import {runIfHasSelecter} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';

const initBonus = function () {
    let $popupSubscriptionBonusWrap = $('.popup-subscription-bonus-wrap');
    let $loadingBlock = $('.loading-block');

    $('[data-button-action="open-popup"]').on('click', function () {
        $popupSubscriptionBonusWrap.addClass('open');
    });

    $('[data-button-action="close-popup"]').on('click', function () {
        $popupSubscriptionBonusWrap.removeClass('open');
    });

    $('[data-button-action="set-settings"]').on('click', function () {
        let $button = $(this);
        let subscriptionId = $(this).data('subscription-id');
        let settingName = $button.data('setting-name');

        $button.prop('disabled', true);

        axios.patch(`${route('api.v1.subscriptions.update_settings', [subscriptionId])}`, {[settingName]: 1}).
            then(function(response) {
                if (settingName === 'keepSubscription') {
                    window.location.href = route('billing.subscriptions.index');
                } else {
                    window.location.reload();
                }
            }).
            catch(function(error) {
                console.log(error);
                $button.prop('disabled', false);
                $popupSubscriptionBonusWrap.removeClass('open');
            });
    });

    // checkbox "auto apply bonus"
    $('.billing-subscription-bonus .billing-subscription__switch-auto-apply input[type="checkbox"]').on('change', function () {
        let $this = $(this);
        let $buttonApply = $this.closest('.billing-subscription-bonus').find('.billing-subscription__button-apply');

        let isEnabled = !!$(this).prop('checked');
        let subscriptionId = $(this).data('subscription-id');
        let settingName = $(this).data('setting-name');

        if (isEnabled) {
            $buttonApply.hide();
        } else {
            $buttonApply.show();
        }

        $this.prop('disabled', true);
        $loadingBlock.addClass('processing');

        axios.patch(`${route('api.v1.subscriptions.update_settings', [subscriptionId])}`, {[settingName]: isEnabled}).
            then(function(response) {
                $loadingBlock.removeClass('processing');
                $this.prop('disabled', false);
            }).
            catch(function(error) {
                console.log(error);

                if (isEnabled) {
                    $buttonApply.show();
                    $this.prop('checked', false);
                } else {
                    $buttonApply.hide();
                    $this.prop('checked', true);
                }

                $loadingBlock.removeClass('processing');
                $this.prop('disabled', false);
            });
    });

    // checkbox "Automatically top up at 0 balance"
    $('.billing-subscription [name="top_up_before_ends"]').on('change', function () {
        let $this = $(this);

        let isEnabled = !!$(this).prop('checked');
        let subscriptionId = $(this).data('subscription-id');
        let settingName = $(this).data('setting-name');

        $this.prop('disabled', true);
        $loadingBlock.addClass('processing');

        axios.patch(`${route('api.v1.subscriptions.update_settings', [subscriptionId])}`, {[settingName]: isEnabled}).
            then(function(response) {
                $loadingBlock.removeClass('processing');
                $this.prop('disabled', false);
            }).
            catch(function(error) {
                console.log(error);

                if (isEnabled) {
                    $this.prop('checked', false);
                } else {
                    $this.prop('checked', true);
                }

                $loadingBlock.removeClass('processing');
                $this.prop('disabled', false);
            });
    });
}

const initTimer = () => {
    const $timers = $('[data-role="timer"]');
    if ($timers.length === 0) {
        return;
    }

    $timers.each(function(index, el) {
        let $timer = $(el);

        let timeleft = parseInt($timer.data('timeleft'));
        if (timeleft <= 3600) {
            timeleft = 3600;
        }

        var seconds_passed = 0;
        function periodicall() {
            seconds_passed++;

            var distance = timeleft - Math.round(seconds_passed / 100);
            var days = Math.floor(distance / 86400);
            var hours = Math.floor(distance / 3600) - days * 24;
            // var hours = Math.floor(distance / 3600);
            var minutes = Math.floor((distance % 3600) / 60);
            var seconds = Math.floor(distance % 60);
            var seconds_parts = Math.floor(60 - 60 * (seconds_passed % 100) / 100);

            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;
            // if (seconds_parts < 10) seconds_parts = "0" + seconds_parts;

            if (days > 0) {
                $timer.html(`<span class="promo_days">${days}<span class="promo_days_title">D</span>&nbsp;:&nbsp;</span><span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;:&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;:&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">S</span></span>`);
            } else if (hours > 0) {
                $timer.html(`<span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;:&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;:&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">S</span></span>`);
            } else {
                $timer.html(`<span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;:&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">S</span></span>`);
            }

            setTimeout(periodicall, 10);
        }

        periodicall();
    });
}

export const initBillingSubscriptionScripts = () => {
    runIfHasSelecter('[data-role="billing-subscriptions"]', [
        initBonus,
        initTimer,
    ]);
}
