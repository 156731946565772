import {runIfHasSelecter, showInfoMessageForm} from "../../utils/panel-lib";
import route from '../index.js';

const initAction = () => {
    $('[data-ajax-action="send"]').on('click', function (e) {
        e.preventDefault();

        let $button = $(this);
        let $form = $(this).closest('form');
        let $infoBlock = $form.find('.info-message-form');

        let data = {
            agency_name: $form.find('[name="agency_name"]').val(),
            website: $form.find('[name="website"]').val(),
            country: $form.find('[name="country"]').val(),
            services: $form.find('[name="services"]').val(),
            plan_offer: $form.find('[name="plan_offer"]').val(),
        }

        if (!data.agency_name.trim() || !data.website.trim() || !data.country.trim() || !data.services.trim() || !data.plan_offer.trim()) {
            showInfoMessageForm(
                $infoBlock,
                'Please fill in all fields.',
                'red'
            );
            $button.prop('disabled', false);

            return;
        }

        $button.prop('disabled', true);

        $.ajax({
            url: route('api.send_are_you_an_agency'),
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    showInfoMessageForm(
                        $infoBlock,
                        'Thank you for submitting your application',
                        'green'
                    );

                    $form.find('.form-group').hide();
                    $button.hide();
                } else {
                    showInfoMessageForm(
                        $infoBlock,
                        response.message,
                        'red'
                    );
                }

                $button.prop('disabled', false);
            },
            error: function () {
                showInfoMessageForm(
                    $infoBlock,
                    'Something went wrong.',
                    'red'
                );
                $button.prop('disabled', false);
            }
        });
    });
}

export const initAreYouAnAgencyScripts = function () {
    runIfHasSelecter('[data-role="are-you-an-agecy"]', [
        initAction
    ]);
}
