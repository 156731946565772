import {runIfHasSelecter} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';

const initScript = () => {
    $('[data-button-action="auth-google"]').on('click', function (e) {
        e.preventDefault();
        let $button = $(this).find('[data-button-action="resend-code"]');
        let $form = $button.closest('form');
        let $formError = $form.find('[data-form-error]');

        $button.prop('disabled', true);

        axios.get(route('api.v1.auth.popup.ext.get_google_oauth2_url')).
            then(response => {
                let data = (response?.data ? response.data : {});

                if (!data) {
                    $button.prop('disabled', false);
                    return;
                }

                if (data.data.redirect) {
                    window.location.href = data.data.redirect;
                }

                if (data.message) {
                    $formError.html(data.message).show();
                    $button.prop('disabled', false);
                }
            }).
            catch(error => {
                let data = (error?.response?.data ? error.response.data : {});
                if (data.message) {
                    $formError.html(data.message).show();
                }

                $button.prop('disabled', false);
            });
    });
}

export const initAuthPopupExtGoogleScripts = function () {
    runIfHasSelecter('.auth-popup', [
        initScript
    ]);
}
