import {formValidationErrorClear, formValidationErrorHandling, runIfHasSelecter} from "../../utils/panel-lib";
import _ from "lodash";
import {displayIframe3dVerification} from '../cart/index.js';

const initCommon = () => {
}

const initForms = function () {
    let xhrAddProject;
    let $forms = $('[data-role="guaranteed-project-add"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function () {
        let $form = $(this);
        let $addButton = $form.find('[data-ajax-action="add-form"]');

        $form.on('submit', function (e) {
            e.preventDefault();

            if (xhrAddProject) {
                xhrAddProject.abort();
            }

            let data = {
                size: $('[name="size"]').val(),
                multiplier_upgrade: 1,
                title: $('[name="title"]').val(),
                url: $('[name="url"]').val(),
            };

            formValidationErrorClear($form);
            $addButton.prop('disabled', true);

            xhrAddProject = $.ajax({
                url: '/api/guaranteed/project/add',
                method: 'POST',
                data: data,
                dataType: 'json',
                success: function(response) {
                    let data = (response.data ? response.data : []);
                    if (response.success) {
                        $addButton.find('.button-text').html('Saved');
                        $addButton.find('.icon-from-button').show();
                        formValidationErrorClear($form);

                        if (data.isDemoProject) {
                            window.location.replace('/project?uid=WTDEMOPROJECT');
                            return;
                        }

                        if (data.id) {
                            window.location.replace('/project?uid=' + data.uid);
                        } else {
                            window.location.replace('/projects');
                        }
                    } else {
                        $addButton.prop('disabled', false);

                        if (response.message === 'Activate your account before you start working') {
                            $('.auth-popup-wrap').addClass('show');
                        }

                        if (response.message) {
                            $form.find('.error-box').html(response.message).show();
                        }

                        if (data.errorsForm) {
                            formValidationErrorHandling($form, data.errorsForm);
                        }

                        $('.project-add-demo-wrapper').hide();
                        $('.domain-checking-progress').removeClass('show');
                    }
                },
                error: function() {
                    $form.find('.error-box').html('An error occurred while saving').show();

                    $addButton.prop('disabled', false);
                    $('.project-add-demo-wrapper').hide();
                    $('.domain-checking-progress').removeClass('show');
                },
            });
        });

        $form.find('input, select, textarea').on('input change', function() {
            formValidationErrorClear($form);
        });

        $addButton.on('click', function (e) {
            e.preventDefault();
            $form.submit();
        });

        if (jsPageData.progressBar === 'show') {
            setTimeout(function() {
                $addButton.trigger('click');
            }, 2500);
        } else {
            $('.project-add-demo-wrapper').hide();
            $('.domain-checking-progress').removeClass('show');
        }
    });
}

export const initGuaranteedProjectAddScripts = () => {
    runIfHasSelecter('[data-role="guaranteed-project-add"]', [
        initCommon,
        initForms
    ]);
}
