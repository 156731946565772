import {formValidationErrorClear, formValidationErrorHandling, runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  const $table = $('#link-building-buy-backlinks-table');
  const $searchInput = $('.search-box input[type="search"]');
  const $loadingBlock = $('.loading-block');

  $table.dataTable({
    responsive: true,
    processing: true,
    dom: `<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>
            <'row'<'col-sm-12'tr>>
            <'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>`,
    order: [[6, 'desc']],
    buttons: [],
    columns: [
      {'width': '20%', orderable: true},
      {'width': '10%', orderable: true},
      {'width': '10%', orderable: false},
      {'width': '20%', orderable: false},
      {'width': '10%', orderable: false},
      {'width': '10%', orderable: true},
      {'width': '15%', orderable: false},
      {'width': '5%', orderable: false},
      {'class': 'hidden'},
    ],
    columnDefs: [
      {
        // targets: 2,
        // render: function(data) {
        //   console.log('Ok 2', data);
        //   if (typeof data === 'undefined' || !data) {
        //     return '';
        //   }
        //
        //   return `<span>${data}</span>`;
        // },
      },
    ],
  });

  // Search
  let localStorageSearch;
  try {
    localStorageSearch = window.localStorage.getItem('st-link-building-buy-backlinks-table-search');
  } catch (e) {
    console.log('Error localStorage.getItem: ' + e.responseText);
  }

  if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
    $searchInput.val(localStorageSearch);
  }

  $searchInput.on('keyup search', function() {
    $table.DataTable().search($(this).val()).draw();
    window.localStorage.setItem('st-link-building-buy-backlinks-table-search',
        $(this).val());
  });

  updateTable();

  function updateTable() {
    $table.DataTable().search($searchInput.val()).ajax.url(route('api.link_building.backlinks_recommendations') + '?is_table=1');

    /** DataTables before receiving the data */
    $table.on('preXhr.dt', function() {
      $loadingBlock.addClass('processing');
    });

    /** DataTables after receiving the data */
    $table.on('xhr.dt', function() {
      $loadingBlock.removeClass('processing');
      $('body').removeClass('loading');

      $('#buy-now-popup').on('show.bs.modal', function(event) {
        let $button = $(event.relatedTarget);
        let $modal = $(this);
        let $modalBody = $modal.find('.modal-body');
        let $modalFooter = $modal.find('.modal-footer');
        let recommendationId = $button.data('recommendation-id');

        $modalBody.find('form').show();
        $modalFooter.find('[data-bs-dismiss="modal"]').addClass('me-sm-3').html('Cancel');
        $modalFooter.find('.btn-ok').data('recommendation-id', recommendationId).show();
      }).find('.btn-ok').on('click', function(e) {
        e.preventDefault();

        let $button = $(this);
        let $modal = $button.closest('.modal');
        let $form = $modal.find('form');

        let data = {
          url: $('[name="website"]').val(),
          keyword: $('[name="keyword"]').val(),
          recommendationId: $button.data('recommendation-id')
        };

        $form.find('.error-box').html('').hide();
        $button.prop('disabled', true);

        $.ajax({
          url: route('api.link_building.buy_backlink'),
          method: 'POST',
          data: data,
          dataType: 'json',
          success: function(response) {
            let data = (response.data ? response.data : []);

            if (response.success) {
              let $modalBody = $modal.find('.modal-body');
              let $modalFooter = $modal.find('.modal-footer');
              $modal.find('[data-bs-dismiss="modal"]').trigger('click');
              $modalBody.find('form').hide();
              // $modalBody.find('.created-api-key').html('Your new API Key: ' + (data.apiKey ? data.apiKey : '')).show();
              $modalFooter.find('[data-bs-dismiss="modal"]').removeClass('me-sm-3').html('Close Window');
              $modalFooter.find('.btn-ok').hide();

              formValidationErrorClear($form);

              window.location.href = route('backlinks.projects.show', data.projectUniquekey) + '#backlinks';
            } else {
              if (response.message) {
                $form.find('.error-box').html(response.message).show();
              }

              if (data.errorsForm) {
                formValidationErrorHandling($form, data.errorsForm);
              }
            }

            $button.prop('disabled', false);
          },
          error: function() {
            $form.find('.error-box').html('An error occurred while processing your request').show();
            $button.prop('disabled', false);
          },
        });
      });
    }).DataTable().ajax.reload();
  }

  $(document).on('click', function (e) {
    /** Button hide-recommendation */
    if ($(e.target).closest('[data-button-action="hide-recommendation"]').length > 0 || typeof $(e.target).data('hide-recommendation') !== 'undefined') {
      let $button = $(e.target);
      if ($(e.target).closest('[data-button-action="hide-recommendation"]').length > 0) {
        $button = $(e.target).closest('[data-button-action="hide-recommendation"]');
      }

      let $recommendationId = $button.data('recommendation-id');

      $button.prop('disabled', true);

      axios.patch(route('api.link_building.recommendation.ignore', $recommendationId)).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data && data.success) {
              updateTable();
            }
          }).
          catch(function() {
            $button.prop('disabled', false);
          });
    }
  });
};

export const initLinkBuildingBuyBacklinksScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="link-building-buy-backlinks"]', [
    initScripts,
  ]);
};
