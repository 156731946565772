import {runIfHasSelecter} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';
import {hideFormError, showFormError} from './index.js';

const initScript = () => {
    let $form = $('[data-form="form-auth-popup__confirmation-code"]');
    let inProgress = false;

    $('.confirmation-code__fields input').on('keydown', function(e) {
        $(this).val('');
    });

    $('.confirmation-code__fields input').on('input', function(e) {
        let $wrap = $(this).closest('.confirmation-code__fields');
        let $inputs = $wrap.find('input[type="text"]');
        let $lastInput = $wrap.find('input[type="text"]:last');
        let val = $(this).val();

        if (val === val.replace(/[0-9]/, '')) {
            $(this).val('');
            return false;
        }

        $inputs.eq($inputs.index(this) + 1).focus();

        let fullval = '';
        $inputs.each(function() {
            let val = parseInt($(this).val());
            fullval = fullval + (val || val === 0 ? val : '');
        });
        $wrap.find('[name="confirmation_code"]').val(fullval);

        if ($wrap.find('[name="confirmation_code"]').val().length === 4) {
            $form.submit();
        }
    });

    $(document).on('paste', function(e) {
        let $confirmationCodeFields = $('.confirmation-code__fields');
        let $confirmationCodeInputs = $confirmationCodeFields.find('input[type="text"]');

        if ($confirmationCodeFields.is(':visible') && $confirmationCodeInputs.length >= 4) {
            let clipboarVal = e.originalEvent?.clipboardData?.getData('text/plain') ?? '';
            let clipboarFoundCode = clipboarVal.replace(/[^0-9]/g, '');
            if (!clipboarFoundCode) {
                $confirmationCodeFields.val('');
            } else {
                let codeArr = clipboarFoundCode.split('');
                let fullVal = '';
                let i = 0;

                $confirmationCodeInputs.each(function() {
                    if (typeof codeArr[i] === 'undefined') {
                        return;
                    }

                    let val = parseInt(codeArr[i]);
                    $(this).val(val);
                    fullVal = fullVal + (val || val === 0 ? val : '');

                    i++;
                });
                $confirmationCodeInputs.blur();
                $confirmationCodeFields.find('[name="confirmation_code"]').val(fullVal);

                if ($confirmationCodeFields.find('[name="confirmation_code"]').val().length === 4) {
                    $form.submit();
                }
            }
        }
    });

    $form.on('submit', function (e) {
        e.preventDefault();

        if (inProgress) {
            return;
        }
        inProgress = true;

        let $button = $(this).find('[data-button-action="resend-code"]');
        let $wrap = $(this).closest('.confirmation-code__fields');
        let $inputs = $wrap.find('input[type="text"]');

        let $formRegistation = $('[data-form="form-auth-popup__registration"]');
        let type = $formRegistation.find('input[name="type"]').val() ?? '';
        let email = $formRegistation.find('input[name="email"]').val()?.trim()?.toLowerCase() ?? '';
        let phone = $formRegistation.find('input[name="phone"]').val()?.trim() ?? '';
        let phoneDialCode = $formRegistation.find('input[name="phone_dial_code"]').val() ?? '';
        let phoneCountryCode = $formRegistation.find('input[name="phone_country_code"]').val() ?? '';

        let data = {
            type: type ?? '',
            user_id: $form.find('input[name="user_id"]').val(),
            confirmation_code: $form.find('input[name="confirmation_code"]').val(),
            phone: phone ?? '',
            phone_dial_code: phoneDialCode ?? '',
            phone_country_code: phoneCountryCode ?? '',
        };

        hideFormError($form);
        $button.prop('disabled', true);
        $inputs.prop('disabled', true);

        const apiUrl = (type === 'phone' || type === 'whatsapp' ? route('api.v1.auth.twilio.check_confirmation_code') : route('api.v1.auth.popup.check_confirmation_code'));

        axios.post(apiUrl, data).
            then(response => {
                let data = (response?.data ? response.data : {});
                if (!data) {
                    $button.prop('disabled', false);
                    $inputs.prop('disabled', false);
                    return;
                }

                if (data.data.redirect) {
                    window.location.href = data.data.redirect;
                }

                if (data.message) {
                    showFormError($form, data.message);
                    $button.prop('disabled', false);
                    $inputs.prop('disabled', false);
                }
            }).
            catch(error => {
                let data = (error?.response?.data ? error.response.data : {});
                if (data.message) {
                    showFormError($form, data.message);
                }

                $button.prop('disabled', false);
                $inputs.prop('disabled', false);
            }).
            finally(() => {
                inProgress = false;
            });
    });
}

export const initAuthPopupConfirmationCodeScripts = function () {
    runIfHasSelecter('[data-role="auth-popup-confirmation-code"]', [
        initScript
    ]);
}
