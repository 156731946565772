import {
  formatPrice,
  runIfHasSelecter,
} from '../../utils/panel-lib';
import _ from 'lodash';

const initCommon = function () {
  let $cart = $('.payment-order');
  let $seoClicksOptionsWrap = $('.seo-traffic-options-wrap');
  let seoClicksPlansOptions = jsPageData.seoClicksPlansOptions;
  let $rangePlanSizes = $('#range_plan_sizes');
  let $inputPlanSize = $('[name="seo-traffic_plan_size"]');
  let $inputSelectQuantity = $('[name="seo-traffic_select_quantity"]');

  function updateData() {
    let planSize = $inputPlanSize.val();
    let selectQuantity = parseInt($inputSelectQuantity.val());

    $('.seo-traffic-plans__header[data-plan-size], .seo-traffic-plans__item[data-plan-size], .seo-traffic-plans__features[data-plan-size]').removeClass('active');
    $('.seo-traffic-plans__header[data-plan-size="' + planSize + '"], .seo-traffic-plans__item[data-plan-size="' + planSize + '"], .seo-traffic-plans__features[data-plan-size="' + planSize + '"]').addClass('active');

    // try {
    //   window.localStorage.setItem('st-seo-traffic-plan-size', planSize);
    //   window.localStorage.setItem('st-seo-traffic-plan-range', planSizeRangeValue);
    // } catch (e) {
    //   console.log('Error localStorage.setItem: ' + e.responseText);
    // }

    let selectPlanOptionId = $('.seo-traffic-options .seo-traffic-options_' + planSize + ' .seo-traffic-options__container.plus-minus').data('plan-option-id');
    let planOptionId = parseInt(selectPlanOptionId);
    let planOption = seoClicksPlansOptions["byId"][planOptionId];
    // let planId = seoClicksPlansOptions["byId"][planOptionId].plan_id;
    let planOptionName = planOption.plan_name;
    let planOptionQuantity = parseInt(planOption.quantity);
    let planOptionSelectQuantity = parseInt($('[name="seo-traffic_select_quantity"]').val());

    // try {
    //   window.localStorage.setItem('st-seo-traffic-option-quantity', selectQuantity);
    // } catch (e) {
    //   console.log('Error localStorage.setItem: ' + e.responseText);
    // }


    let firstKey = _.keys(seoClicksPlansOptions["bySize"][planSize])[0]
    let planOption1 = seoClicksPlansOptions["bySize"][planSize][firstKey];

    let planOptionPerCredit = parseFloat(planOption1.price);
    if (planOptionSelectQuantity >= 250 && planOptionSelectQuantity < 660) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.17);
    } else if (planOptionSelectQuantity >= 660 && planOptionSelectQuantity < 1330) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.39);
    } else if (planOptionSelectQuantity >= 1330 && planOptionSelectQuantity < 2800) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.53);
    } else if (planOptionSelectQuantity >= 2800 && planOptionSelectQuantity < 6000) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.64);
    } else if (planOptionSelectQuantity >= 6000 && planOptionSelectQuantity < 10000) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.66);
    } else if (planOptionSelectQuantity >= 10000 && planOptionSelectQuantity < 20000) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.70);
    } else if (planOptionSelectQuantity >= 20000) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.72);
    }

    if (jsAppData.isReseller) {
      planOptionPerCredit = parseFloat(planOption1.price_per_unit);
    }

    let planOptionAmount = planOptionPerCredit * planOptionSelectQuantity;
    let planOptionSaving = (parseFloat(planOption1.price) - planOptionPerCredit) * planOptionSelectQuantity;

    $seoClicksOptionsWrap.removeClass('active');
    $('.seo-traffic-options_' + planSize).addClass('active');

    $('.seo-traffic-options__container').removeClass('active');
    $('.seo-traffic-options__container[data-plan-option-id="' + planOptionId + '"]').addClass('active');

    let textCredits = (planOptionSelectQuantity === 1 ? 'Credit' : 'Credits');
    let textBacklinks = (planOptionSelectQuantity === 1 ? 'SEO Visit' : 'SEO Visits');

    $('.backlinks-options__for-js-name').html('<span class="text-gray d-inline-block">~' + planOptionSelectQuantity + '&nbsp;' + textBacklinks + '</span>');
    $('.backlinks-options__for-js-price-per-credit').html('<span class="text-gray">Per SEO Visit</span>&nbsp;$' + formatPrice(planOptionPerCredit));
    $('.backlinks-options__for-js-amount').html('$' + formatPrice(planOptionAmount));

    $('[name="seo-traffic_plan_option_id"]').val(planOptionId);
    $('[name="seo-traffic_select_quantity"]').val(planOptionSelectQuantity);
    $('[name="seo-traffic_plan_price_per_credit"]').val(planOptionPerCredit.toFixed(2));
    $('[name="seo-traffic_plan_amount"]').val(planOptionAmount.toFixed(2));

    // let $seoClicksOptionsContainerPlusMinus = $('.seo-traffic-options__container.plus-minus[data-plan-option-id="' + planOptionId + '"]');
    // $seoClicksOptionsContainerPlusMinus.find('.seo-traffic-options__amount span').html('$' + formatPrice(planOptionAmount));
    // $seoClicksOptionsContainerPlusMinus.find('.seo-traffic-options__saving span').html('Save $' + formatPrice(planOptionSaving));
    // $seoClicksOptionsContainerPlusMinus.find('.seo-traffic-options__price-per-credit').html('Price per credit $' + formatPrice(planOptionPerCredit));

    // $('.button-select-quantity[data-credit-type="website-traffic"] .js-insert').html('+ Select quantity (' + planOptionName + ') ');
    // $('.bc-seo-traffic-traffic .add-to-cart-step').html('Add to Cart <span class="plan-color--purple">' + planOption.plan_name + '</span>');
  }

  // plan options
  // $(".seo-traffic-options__container").on('click', function () {
  //   $('.seo-traffic-options__container').removeClass('active');
  //   $(this).addClass('active');
  //   $('[name="seo_traffic_size_quantity"]').val($(this).data('quantity'));
  //
  //   updateData();
  // });

  let stPlanOptionQuantity;
  // try {
  //   stPlanOptionQuantity = window.localStorage.getItem('st-seo-traffic-option-quantity');
  // } catch (e) {
  //   console.log('Error localStorage.getItem: ' + e.responseText);
  // }

  if (stPlanOptionQuantity && parseInt(stPlanOptionQuantity) >= 100) {
    $inputSelectQuantity.val(stPlanOptionQuantity);
  }
  if (jsAppData.isReseller) {
    $inputSelectQuantity.val(1);
  }

  // updateData();
};


export const initBuySeoTrafficScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="buy-link-building"]', [
    initCommon
  ]);
};
