import { runIfHasSelecter } from "../../utils/panel-lib";
import route from '../index.js';

const initButtons = function (currentData) {
    $('[data-credit-button]').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const size = $button.data('credit-button')

        $button.attr("disabled", true);

        $.ajax({
            method: 'POST',
            url: '/api/guaranteed/project/renew',
            data: {
                uid: currentData.projectUid,
                id: currentData.projectId,
                size: size,
            },
            dataType: 'json',
            success: function (response) {
                const data = response.data;

                if (data.url) {
                    window.location.href = data.url;
                } else {
                    window.location.href = '/projects';
                }
            },
            error: function () {
                $button.attr("disabled", false);
            }
        });
    });

    $('[data-action-button="convert"]').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const projectId = parseInt($button.data('project-id'));

        $button.attr("disabled", true);

        $.ajax({
            method: 'POST',
            url: route('api.website-traffic.projects.convert', currentData.projectId),
            data: {},
            dataType: 'json',
            success: function (response) {
                const data = response.data;

                if (data.uniqueKey) {
                    window.location.href = route('guaranteed.projects.edit') + '?uid=' + data.uniqueKey;
                } else {
                    window.location.href = route('website_traffic.projects.index');
                }
            },
            error: function () {
                $button.attr("disabled", false);
            }
        });
    });
}

export const initGuaranteedProjectRenewScripts = () => {
    runIfHasSelecter('[data-role="guaranteed-renew"]', [
        initButtons,
    ]);
}
