import {
  formValidationErrorClear,
  formValidationErrorHandling,
  runIfHasSelecter,
} from '../../utils/panel-lib.js';
import route from '../index.js';

const initCommon = function() {
  let $buttonConvert = $('.convert-credits [data-ajax-action="convert-credits"]');

  function initPlusMinusToCart() {
    let $quantityInputNumber = $('.convert-credits .quantity-plus-minus .input-number');
    let $quantityBtnNumber = $('.convert-credits .quantity-plus-minus .btn-number');

    $quantityBtnNumber.on('click', function(e) {
      e.preventDefault();

      let fieldName = $(this).data('field');
      let type = $(this).data('type');
      let input = $('.convert-credits .quantity-plus-minus input[name="' + fieldName + '"]');
      let currentVal = parseInt(input.val());

      if (!isNaN(currentVal)) {
        if (type === 'minus') {
          if (currentVal > parseInt(input.attr('min'))) {
            input.val(currentVal - 1).change();
          }
          if (parseInt(input.val()) === parseInt(input.attr('min'))) {
            $(this).prop('disabled', true);
          }
        } else if (type === 'plus') {
          if (currentVal < parseInt(input.attr('max'))) {
            input.val(currentVal + 1).change();
          }
          if (parseInt(input.val()) === parseInt(input.attr('max'))) {
            $(this).prop('disabled', true);
          }
        }
      } else {
        input.val(0);
      }

      if (currentVal <= 0) {
        $buttonConvert.prop('disabled', true);
      } else {
        $buttonConvert.prop('disabled', false);
      }
    });

    $quantityInputNumber.on('focusin', function() {
      $(this).data('oldValue', $(this).val());
    });

    $quantityInputNumber.on('change', function() {
      let minValue = parseInt($(this).attr('min'));
      let maxValue = parseInt($(this).attr('max'));
      let valueCurrent = parseInt($(this).val());
      let name = $(this).attr('name');
      let needUpdateCart = true;
      let size = $(this).data('size');

      if (valueCurrent >= minValue) {
        $('.convert-credits .quantity-plus-minus .btn-number[data-type="minus"][data-field="' + name + '"]').
            prop('disabled', false);
      } else {
        $(this).val($(this).data('oldValue'));
      }
      if (valueCurrent <= maxValue) {
        $('.convert-credits .quantity-plus-minus .btn-number[data-type="plus"][data-field="' + name + '"]').
            prop('disabled', false);
      } else {
        $(this).val($(this).data('oldValue'));
      }

      if ($(this).val() > 1) {
        $('.convert-credits .quantity-plus-minus .btn-number[data-type="minus"]').prop('disabled', false);
      } else {
        $('.convert-credits .quantity-plus-minus .btn-number[data-type="minus"]').prop('disabled', true);
      }

      if ($(this).val() <= 0) {
        $buttonConvert.prop('disabled', true);
      } else {
        $buttonConvert.prop('disabled', false);
      }

      updateGetCredits(size);
    });

    $quantityInputNumber.on('input', function() {
      let valueCurrent = parseInt($(this).val());

      if (valueCurrent <= 0) {
        $buttonConvert.prop('disabled', true);
      } else {
        $buttonConvert.prop('disabled', false);
      }
    });

    $quantityInputNumber.on('keydown', function(e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
          // Allow: Ctrl+A
          (e.keyCode === 65 && e.ctrlKey === true) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
          (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    });
  }

  initPlusMinusToCart();

  function updateGetCredits(size) {
    let credits2Hits = {
      "mini": 60000,
      "medium": 300000,
      "large": 600000,
      "ultimate": 1000000,
      "max": 10000000,
      "unlim": 1,
    };

    let oldSize = $('.convert-credits select.select-old-credits[data-size="' + size + '"]').val();
    let newSize = $('.convert-credits select.select-new-credits[data-size="' + size + '"]').val();
    let credits = $('.convert-credits .quantity-plus-minus .input-number[name="quant[' + size + ']"]').val();
    let getCredits = parseInt(credits) * parseInt(credits2Hits[size]);

    if (oldSize && newSize && oldSize !== newSize) {
      if ((oldSize.indexOf('alexa') === -1 && oldSize.indexOf('pro') === -1) && oldSize.indexOf('expert') === -1 && newSize === 'unlim_pro') {
        getCredits = Math.floor(getCredits / 2);
      } else if ((oldSize.indexOf('alexa') === -1 && oldSize.indexOf('pro') === -1) && oldSize.indexOf('expert') === -1 && newSize === 'unlim_expert') {
        getCredits = Math.floor(getCredits / 3);
      } else if ((oldSize.indexOf('alexa') !== -1 || oldSize.indexOf('pro') !== -1) && newSize === 'unlim') {
        getCredits = Math.floor(getCredits * 2);
      } else if ((oldSize.indexOf('alexa') !== -1 || oldSize.indexOf('pro') !== -1) && newSize === 'unlim_expert') {
        getCredits = Math.floor(getCredits / 1.5);
      } else if (oldSize.indexOf('expert') !== -1 && newSize === 'unlim') {
        getCredits= Math.floor(getCredits * 3);
      } else if (oldSize.indexOf('expert') !== -1 && newSize === 'unlim_pro') {
        getCredits = Math.floor(getCredits * 1.5);
      }
    }

    $('.convert-credits [name="get_credits_' + size + '"]').val(getCredits);

    if (getCredits <= 0) {
      $buttonConvert.prop('disabled', true);
    } else {
      $buttonConvert.prop('disabled', false);
    }
  }

  $('.convert-credits select.select-old-credits').on('change', function(e) {
    let size = $(this).data('size');
    let credits = $(this).find('option:selected').data('credits');

    $('.convert-credits .quantity-plus-minus .input-number[name="quant[' + size + ']"]').attr('max', credits).val(credits).trigger('change');
    updateGetCredits(size);
  }).trigger('change');

  $('.convert-credits select.select-new-credits').on('change', function(e) {
    let size = $(this).data('size');
    updateGetCredits(size);
  });

  $buttonConvert.on('click', function(e) {
    e.preventDefault();

    let $button = $(this);
    let size = $button.data('size');
    let oldSize = $('.convert-credits select.select-old-credits[data-size="' + size + '"]').val();
    let newSize = $('.convert-credits select.select-new-credits[data-size="' + size + '"]').val();
    let credits = $('.convert-credits .quantity-plus-minus .input-number[name="quant[' + size + ']"]').val();

    let data = {
      oldSize: oldSize,
      newSize: newSize,
      credits: credits
    };

    $button.prop('disabled', true);

    axios.post(route('api.v1.billing.website_traffic.convert_credits'), data).
        then(function(response) {
          let data = (response.data ? response.data : {});
          if (data && data.success) {
            document.location.reload();
          } else {
            $button.prop('disabled', false);
          }
        }).
        catch(function(error) {
          console.log(error);
          $button.prop('disabled', false);
        });
  });
};

export const initBillingBalanceScripts = () => {
  runIfHasSelecter('[data-role="billing-balance"]', [
    initCommon,
  ]);
};
