import _, {isString} from 'lodash';
import route from '../panel/index.js';
import axios from 'axios';

let isProcessingAddingToCart = false;
let addToCartTimeout = null;

(function() {
  var throttle = function(type, name, obj) {
    obj = obj || window;
    var running = false;
    var func = function() {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function() {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  throttle('resize', 'optimizedResize');
})();

let exchangeRate = null;

export const runIfHasSelecter = function(selector, handlers) {
  const $element = $(selector);
  if (0 === $element.length) {
    return;
  }

  const data = $element.data('data') || {};
  const translations = $element.data('translations') || {};

  _.map(handlers, function(handler) {
    handler(data, translations);
  });
};

export const makeQueryString = (params) => _.map(_.keys(params),
    (key) => `${key}=${params[key]}`).join('&');

export const escapeHtml = function(string) {
  const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };

  return String(string).replace(/[&<>"'`=\/]/g, function(s) {
    return entityMap[s];
  });
};

export const roundDown = (value) => ((parseInt(value * 100)) / 100);

export const prepareChartData = function(
    ctx, projectData, chartSize = 'normal'
) {
  if (!ctx || !projectData) {
    return {};
  }

  let typeTraffic = projectData.typeTraffic;
  let typeChart = (projectData.typeChart ? projectData.typeChart : 'line');
  let chartLabel = (projectData.translations &&
  projectData.translations.clicks
      ? projectData.translations.clicks
      : 'Clicks');

  if (typeTraffic === 'hits') {
    chartLabel = (projectData.translations &&
    projectData.translations.pageViews
        ? projectData.translations.hits
        : 'Hits');
  } else if (typeTraffic === 'visits') {
    chartLabel = (projectData.translations &&
    projectData.translations.visits
        ? projectData.translations.visits
        : 'Visits');
  } else if (typeTraffic === 'clicks') {
    chartLabel = (projectData.translations &&
    projectData.translations.clicks
        ? projectData.translations.clicks
        : 'Clicks');
  }

  let chartLabels = projectData.labels;
  // let maxChartLabels = (chartLabels.length > 10 ? Math.ceil((chartLabels.length) / 5) : 10);
  let maxChartLabels = (chartLabels && chartLabels.length > 10 ? 6 : 10);

  let chartData = projectData.data;
  let maxHits = parseInt(projectData.maxHits);
  maxHits = (maxHits < 0 ? 0 : maxHits);
  let maxHitsD = parseInt(maxHits + ((maxHits / 3) * 2));

  let minY = 0;
  let maxY = 10;

  if (maxHitsD <= 10) {
    maxY = 10;
  } else if (maxHitsD > 10 && maxHitsD <= 30) {
    maxY = 30;
  } else if (maxHitsD > 30 && maxHitsD <= 50) {
    maxY = 50;
  } else if (maxHitsD > 50 && maxHitsD <= 100) {
    maxY = 100;
  } else if (maxHitsD > 100 && maxHitsD <= 300) {
    maxY = 300;
  } else if (maxHitsD > 300 && maxHitsD <= 500) {
    maxY = 500;
  } else if (maxHitsD > 500 && maxHitsD <= 1000) {
    maxY = 1000;
  } else if (maxHitsD > 1000 && maxHitsD <= 3000) {
    maxY = 3000;
  } else if (maxHitsD > 3000 && maxHitsD <= 5000) {
    maxY = 5000;
  } else if (maxHitsD > 5000 && maxHitsD <= 10000) {
    maxY = 10000;
  } else if (maxHitsD > 10000) {
    let maxHits1 = parseInt(maxHitsD / 1000);
    // let maxHits2 = parseInt(maxHitsD % 1000);

    maxHitsD = parseInt(String(maxHits1) + '000');
    maxY = maxHitsD + 3000;
  }

  let stepSize = parseInt(maxY / 10);
  let maxTicksLimit = 6;

  if (typeChart === 'bar') {
    maxHitsD = parseInt(maxHits + (maxHits / 10));
    minY = 0;
    maxY = 10;

    if (maxHitsD <= 10) {
      maxY = 10;
    } else if (maxHitsD > 10 && maxHitsD <= 50) {
      maxY = 50;
    } else if (maxHitsD > 50 && maxHitsD <= 100) {
      maxY = 100;
    } else if (maxHitsD > 100 && maxHitsD <= 150) {
      maxY = 150;
    } else if (maxHitsD > 150 && maxHitsD <= 200) {
      maxY = 200;
    } else if (maxHitsD > 200 && maxHitsD <= 250) {
      maxY = 250;
    } else if (maxHitsD > 250 && maxHitsD <= 300) {
      maxY = 300;
    } else if (maxHitsD > 300 && maxHitsD <= 350) {
      maxY = 350;
    } else if (maxHitsD > 350 && maxHitsD <= 400) {
      maxY = 400;
    } else if (maxHitsD > 400 && maxHitsD <= 450) {
      maxY = 450;
    } else if (maxHitsD > 450 && maxHitsD <= 500) {
      maxY = 500;
    } else if (maxHitsD > 500 && maxHitsD <= 550) {
      maxY = 550;
    } else if (maxHitsD > 550 && maxHitsD <= 600) {
      maxY = 600;
    } else if (maxHitsD > 600 && maxHitsD <= 650) {
      maxY = 650;
    } else if (maxHitsD > 650 && maxHitsD <= 700) {
      maxY = 700;
    } else if (maxHitsD > 700 && maxHitsD <= 750) {
      maxY = 750;
    } else if (maxHitsD > 750 && maxHitsD <= 800) {
      maxY = 800;
    } else if (maxHitsD > 800 && maxHitsD <= 850) {
      maxY = 850;
    } else if (maxHitsD > 850 && maxHitsD <= 900) {
      maxY = 900;
    } else if (maxHitsD > 900 && maxHitsD <= 950) {
      maxY = 950;
    } else if (maxHitsD > 950 && maxHitsD <= 1000) {
      maxY = 1000;
    } else if (maxHitsD > 1000 && maxHitsD <= 1500) {
      maxY = 1500;
    } else if (maxHitsD > 1500 && maxHitsD <= 2000) {
      maxY = 2000;
    } else if (maxHitsD > 2000 && maxHitsD <= 2500) {
      maxY = 2500;
    } else if (maxHitsD > 2500 && maxHitsD <= 3000) {
      maxY = 3000;
    } else if (maxHitsD > 3000 && maxHitsD <= 3500) {
      maxY = 3500;
    } else if (maxHitsD > 3500 && maxHitsD <= 4000) {
      maxY = 4000;
    } else if (maxHitsD > 4000 && maxHitsD <= 4500) {
      maxY = 4500;
    } else if (maxHitsD > 4500 && maxHitsD <= 5000) {
      maxY = 5000;
    } else if (maxHitsD > 5000 && maxHitsD <= 5500) {
      maxY = 5500;
    } else if (maxHitsD > 5500 && maxHitsD <= 6000) {
      maxY = 6000;
    } else if (maxHitsD > 6000 && maxHitsD <= 6500) {
      maxY = 6500;
    } else if (maxHitsD > 6500 && maxHitsD <= 7000) {
      maxY = 7000;
    } else if (maxHitsD > 7000 && maxHitsD <= 7500) {
      maxY = 7500;
    } else if (maxHitsD > 7500 && maxHitsD <= 8000) {
      maxY = 8000;
    } else if (maxHitsD > 8000 && maxHitsD <= 8500) {
      maxY = 8500;
    } else if (maxHitsD > 8500 && maxHitsD <= 9000) {
      maxY = 9000;
    } else if (maxHitsD > 9000 && maxHitsD <= 9500) {
      maxY = 9500;
    } else if (maxHitsD > 9500 && maxHitsD <= 10000) {
      maxY = 10000;
    } else if (maxHitsD > 10000) {
      let maxHits1 = parseInt(maxHitsD / 1000);
      // let maxHits2 = parseInt(maxHitsD % 1000);

      maxHitsD = parseInt(String(maxHits1) + '000');
      maxY = maxHitsD + 2000;
      stepSize = 5;
    }

    maxTicksLimit = 6;
    stepSize = parseInt(maxY / (maxTicksLimit - 1));
  }

  let gradient = ctx.createLinearGradient(0, 0, 0, 80);
  gradient.addColorStop(0, 'rgba(82, 165, 107, 0.5)');
  gradient.addColorStop(1, 'rgba(82, 165, 107, 0.1)');

  let data = {
    labels: chartLabels,
    datasets: [
      {
        label: chartLabel,
        data: chartData,
        ticks: [],
        fill: 'start',
        borderWidth: (typeChart === 'bar' ? 0.5 : 0.5),
        borderColor: (typeChart === 'bar' ? '#2D8EFF' : '#2D8EFF'),
        backgroundColor: (typeChart === 'bar' ? 'rgba(92, 168, 255,0.8)' : 'rgba(92, 168, 255,0.8)'),
        tension: 0.4,
        pointRadius: 0,
        // barThickness: 30,
      }],
  };

  let config = {
    type: typeChart,
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        filler: {
          propagate: false,
        },
        title: {
          display: false,
          text: (ctx) => 'Fill: ' + ctx.chart.data.datasets[0].fill,
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          multiKeyBackground: 'rgba(92, 168, 255,0.8)',
          // boxWidth: 0,
          boxPadding: 0,
          borderWidth: 0,
        },
        hover: {
          mode: null,
        },
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            padding: 0,
            color: 'rgba(31,41,59, 0.5)',
            autoSkip: false,
            align: 'center',
            crossAlign: 'near',
            // minRotation: 90,
            // maxRotation: 0,
            // callback: function(val, index) {
            //     return index % 4 === 0 ? this.getLabelForValue(val) : '';
            // },
          },
        },
        y: {
          display: false,
          grid: {
            display: true,
            drawBorder: false,
            color: '#E0E0E0',
          },
          ticks: {
            display: false,
            padding: 0,
            stepSize: stepSize,
          },
          min: minY,
          max: maxY,
          beginAtZero: true,
        },
      },
      interaction: {
        intersect: false,
      },
      padding: 0,
    },
  };

  if (chartSize === 'normal') {
    data.datasets[0].borderWidth = (typeChart === 'bar' ? '1' : '1');

    config.options.scales = {
      x: {
        display: true,
        grid: {
          display: false,
          drawBorder: false,
          offset: false,
          color: '#0C243C0D',
        },
        border: {
          width: 1,
          color: '#E7F0F6',
          dash: []
        },
        ticks: {
          display: (typeChart !== 'bar'),
          padding: 0,
          color: 'rgba(31,41,59, 0.5)',
          autoSkip: true,
          // autoSkipPadding: 40,
          // includeBounds: false,
          align: 'center',
          crossAlign: 'near',
          // minRotation: 45,
          maxRotation: 0,
          maxTicksLimit: maxChartLabels,
          // mirror: true,
          // callback: function(val, index) {
          //     return index % stepChartLabels === 0 ? this.getLabelForValue(val) : '';
          // },
          // callback: function(val, index) {
          //     if (index === 0 || index === (chartLabels.length - 1)) {
          //         return this.getLabelForValue(val);
          //     }
          //
          //     if (index < 5 || (chartLabels.length - 1) - index < 4) {
          //         return '';
          //     }
          //
          //     if (index % stepChartLabels === 0) {
          //         return this.getLabelForValue(val);
          //     }
          //
          //     return '';
          // },
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          drawBorder: false,
          color: '#E0E0E0',
          offset: false,
          tickLength: 6,
          tickWidth: 0,
        },
        border: {
          width: 1,
          color: '#E0E0E0',
          dash: [4, 3],
          // dashOffset: 0.8,
          // z: -1,
        },
        ticks: {
          display: true,
          padding: 0,
          color: 'rgba(31,41,59, 0.5)',
          stepSize: stepSize,
          // autoSkip: true,
          maxTicksLimit: maxTicksLimit,
        },
        min: minY,
        max: maxY,
        beginAtZero: true,
      },
    };
  }

  return config;
};

export const formatNumber = function(value) {
  let txt = parseInt(value).toLocaleString('en-US').toString();

  return _.replace(txt, /,/g, ' ');
};

export const formatPrice = function(value, fixed = 2, isFloor = false) {
  if (isFloor) {
    value = roundDown(value).toFixed(fixed);
  } else {
    value = parseFloat(value).toFixed(fixed);
  }

  let parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join('.');
};

export const formatFloatNumber = function(value, fixed = 2) {
  return parseFloat(parseFloat(value).toFixed(fixed)).
      toLocaleString().
      toString();
  // return parseFloat((Math.round(value * 100) / 100).toFixed(fixed)).toLocaleString().toString();
};

export const formatTimeleft = function(
    distance,
    showZeroMinutes = false,
) {
  if (distance <= 0) {
    return 'EXPIRED';
  }

  var days = Math.floor(distance / (60 * 60 * 24));
  var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
  var minutes = Math.floor((distance % (60 * 60)) / (60));

  var expire_text = '';
  if (days > 1) expire_text += days + ' Days ';
  else if (days > 0) expire_text += days + ' Day ';

  if (hours > 1) expire_text += hours + ' Hours ';
  else if (hours > 0) expire_text += hours + ' Hour ';

  if (days === 0 && minutes > 1) {
    expire_text += minutes + ' Minutes ';
  } else if (days === 0 && minutes > 0) {
    expire_text += minutes + ' Minute ';
  } else if (days === 0 && minutes < 1 && showZeroMinutes) {
    expire_text += '<1 Minute ';
  }

  return expire_text;
};

export const formatLastDate = function(
    distance,
    showZeroMinutes = false,
) {
  if (distance <= 0) {
    return '';
  }

  let days = Math.floor(distance / (60 * 60 * 24));
  let hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
  let minutes = Math.floor((distance % (60 * 60)) / (60));

  let lastDateText = '';
  if (days > 1) {
    lastDateText += days + ' days ';
  } else if (days > 0) {
    lastDateText += days + ' day ';
  }

  if (hours > 1) {
    lastDateText += hours + ' hours ';
  } else if (hours > 0) {
    lastDateText += hours + ' hour ';
  }

  if (days === 0 && minutes > 1) {
    lastDateText += minutes + ' minutes ';
  } else if (days === 0 && minutes > 0) {
    lastDateText += minutes + ' minute ';
  } else if (days === 0 && minutes < 1 && showZeroMinutes) {
    lastDateText += '<1 minute ';
  }

  return lastDateText;
};

export const formatNumberString = function(value) {
  let txt = parseInt(value).toLocaleString('en-US').toString();

  return _.replace(txt, /,/g, ' ');
};

export const formatNumberK = n => n >= 1000 ? formatNumberString(`${(n / 1000).toFixed(1)}`) + 'K' : `${n}`;

export const getUserLanguage = function() {
  let language = (window.navigator && window.navigator.language
      ? window.navigator.language
      : 'en');

  language = language.slice(0, 2).toLowerCase();

  return language;
};

export const abbrNumberFormat = function(value, precision = 1, useFloor = false) {
  if (isString(value) || isNaN(value)) {
    if (typeof value.includes === 'function' && (value.includes('K') || value.includes('M') || value.includes('B'))) {
      return value;
    }
  }

  const map = [
    // {suffix: 'T', threshold: 1e12},
    // {suffix: 'B', threshold: 1e9},
    {suffix: 'M', threshold: 1e6},
    {suffix: 'K', threshold: 1e3},
    {suffix: '', threshold: 1},
  ];

  const found = map.find((x) => Math.abs(value) >= x.threshold);
  if (found) {
    let floatNumber = value / found.threshold;
    let formatted = (Math.floor( floatNumber * 10) / 10) + found.suffix;
    if (useFloor) {
      formatted = (floatNumber).toFixed(precision) + found.suffix;
    }

    if (typeof formatted.replaceAll === 'function') {
      formatted = formatted.replaceAll('.0', '');
    }

    return formatted;
  }

  return parseInt(value).toFixed(precision).replaceAll('.0', '');
};

export const str_repeat = function($string, $multiplier) {
  return new Array($multiplier + 1).join($string);
};

export const showInfoMessageForm = function(
    $element, message, color = 'green',
) {
  let $elInfoMessage = $element.closest('form').find('.info-message-form');

  if ($elInfoMessage.length === 0) {
    return;
  }

  let $elInfoMessageDesc = $elInfoMessage.find('.info-message-form__desc');
  let classColor = 'info-message_color_' + color;

  $elInfoMessageDesc.html(message);
  $elInfoMessage.attr('class', 'info-message-form').
      addClass(classColor).
      show();
};

export const formValidationErrorHandling = function($form, errors) {
  for (let key in errors) {
    let $input = $form.find('[name="' + key + '"]');

    if (errors[key].length <= 0) {
      continue;
    }

    let $messagesHtml = '';
    for (let i = 0; i < errors[key].length; i++) {
      $messagesHtml += errors[key][i] + '<br>';
      if (i + 1 < errors[key].length) {
        $messagesHtml += '<br>';
      }
    }

    if ($input.length > 0) {
      let invalidFeedbackId = 'input-' + key + '-error';
      let invalidFeedbackHtml = '<div id="' + invalidFeedbackId +
          '" class="invalid invalid-feedback">' + $messagesHtml +
          '</div>';

      $input.addClass('invalid');
      $('#' + invalidFeedbackId).remove();

      if ($input.prop('type') === 'checkbox') {
        $input.next('label').after(invalidFeedbackHtml);
      } if ($input.prop('type') === 'select-one' && $input.hasClass('custom-select')) {
        $input.closest('.form-group').append(invalidFeedbackHtml);
      } else {
        $input.after(invalidFeedbackHtml);
      }
    } else {
      let $el = $form.find('.' + key);
      if ($el.length > 0) {
        $el.html($messagesHtml).show();
      } else {
        $form.find('.error-box, [data-form-error]').html($messagesHtml).show();
      }
    }
  }
};

export const formValidationErrorClear = function($form) {
  $form.find('.error-box, [data-form-error], .success-box').html('').hide();

  $form.find('input, select, textarea').each(function() {
    $(this).removeClass('invalid');

    if ($(this).attr('type') === 'checkbox') {
      $(this).next('label').next('.invalid-feedback').remove();
    } else {
      $(this).next('.invalid-feedback').remove();
    }
  });
};

export const sendGaEvent = function(event, data) {
  if (data && typeof window.dataLayer !== 'undefined' && typeof window.gtag === 'function') {
    // window.dataLayer.push('event', 'purchase');
    // console.log('dataLayer', dataLayer);

    // window.dataLayer.push({
    //     "event": event,
    //     data
    // });

    window.gtag('event', event, data);
    // console.log('dataLayer', window.dataLayer);
  }
};

export const getGaClientId = function() {
  let cookie = {};

  document.cookie.split(';').forEach(function(el) {
    let splitCookie = el.split('=');
    let key = splitCookie[0].trim();
    cookie[key] = splitCookie[1];
  });

  return cookie['_ga'] ? cookie['_ga'].substring(6) : '';
};

export const getKeyByValue = function(object, value) {
  return Object.keys(object).find(key => object[key] === value);
};

export const initTooltips = function () {
  if (typeof bootstrap === 'undefined') {
    return;
  }

  const deviceType = $('[data-device-type]').data('device-type');

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map(function(tooltipTriggerEl) {
    let $tooltipTriggerEl = $(tooltipTriggerEl);
    let tpl = $tooltipTriggerEl.data('tooltip-tpl');
    let toBody = $tooltipTriggerEl.data('tooltip-body');
    let placement = $tooltipTriggerEl.data('tooltip-placement');
    let template = '<div class="tooltip ' + tpl +
        '" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
    let tooltipTrigger = (deviceType === 'phone' || deviceType === 'tablet'
        ? 'hover'
        : 'hover focus');

    if ($tooltipTriggerEl.data('tooltip-trigger')) {
      tooltipTrigger = $tooltipTriggerEl.data('tooltip-trigger');
    }

    return new bootstrap.Tooltip(tooltipTriggerEl, {
      container: (toBody ? 'body' : tooltipTriggerEl),
      // container: 'body',
      placement: (placement ? placement : 'auto'),
      // fallbackPlacements: ['top', 'right', 'bottom', 'left'],
      trigger: tooltipTrigger,
      // trigger: 'click',
      // boundary: 'window',
      // offset: [0, 0],
      template: template,
      sanitize: false,
    });
  });

  if (typeof jsAppTooltips !== 'undefined') {
    for (let key in jsAppTooltips) {
      let jsAppTooltip = jsAppTooltips[key];

      if (jsAppTooltip.title && typeof jsAppTooltip.title.replaceAll === 'function') {
        jsAppTooltip.title = jsAppTooltip.title.replaceAll('"', "'");
      }

      if (jsAppTooltip.description && typeof jsAppTooltip.description.replaceAll === 'function') {
        jsAppTooltip.description = jsAppTooltip.description.replaceAll('"', "'");
      }

      let intercomButton = `<button class='button-tell-me button button-link-dashed' type='button' onclick='showMessageIntercomFromTooltip(this)' data-title='${jsAppTooltip.title}'><span>${jsAppTooltip.intercom_button_title ? jsAppTooltip.intercom_button_title : 'tell me more about it'}</span></button>`;

      let elemsForTooltip = null;
      if (jsAppTooltip.type === 'form' && jsAppTooltip.name) {
        elemsForTooltip = document.querySelectorAll('label[for="' + jsAppTooltip.name + '"]');
      } else if (jsAppTooltip.type === 'button' && jsAppTooltip.selector) {
        elemsForTooltip = document.querySelectorAll('button' + jsAppTooltip.selector + ', .button' + jsAppTooltip.selector);
      } else if (jsAppTooltip?.selector) {
        elemsForTooltip = document.querySelectorAll(jsAppTooltip.selector);
      }

      if (elemsForTooltip) {
        let placement = jsAppTooltip.placement ? jsAppTooltip.placement : 'auto';
        let tooltipTrigger = (deviceType === 'phone' || deviceType === 'tablet' ? 'hover' : jsAppTooltip.trigger);

        let template = `<div class="tooltip ${jsAppTooltip.template ? jsAppTooltip.template : 'dark'}" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`;

        let tooltipHtml = '';
        tooltipHtml += `<span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="<div class='title'>${jsAppTooltip.title}</div>${jsAppTooltip.description}${jsAppTooltip.intercom_button_enabled ? intercomButton : ''}">`;
        tooltipHtml += `  <img src="/img/icons/${jsAppTooltip.icon_filename ? jsAppTooltip.icon_filename : 'icon_question-tooltip-blue.svg'}" alt="">`;
        tooltipHtml += `</span>`;

        [...elemsForTooltip].map(function (elemForTooltip) {
          if (elemForTooltip.style.display === 'none') {
            return;
          }

          if (typeof elemForTooltip.dataset !== 'undefined' && elemForTooltip.dataset.tooltipStatus === 'hide') {
            return;
          }

          if ($(elemForTooltip).next('.tooltip-icon-wrap').length > 0 || $(elemForTooltip).children('.tooltip-icon-wrap').length > 0) {
            return;
          }

          let tooltipEl = document.createElement('div');
          tooltipEl.className = 'tooltip-icon-wrap type-' + (jsAppTooltip.type ? jsAppTooltip.type : 'default');
          tooltipEl.innerHTML = tooltipHtml;

          if (jsAppTooltip.type === 'button') {
            tooltipEl.style.top = (elemForTooltip.offsetTop - 8) + 'px';
            tooltipEl.style.left = (elemForTooltip.offsetLeft + elemForTooltip.clientWidth - 8) + 'px';
            tooltipEl.style.right = 'auto';
            elemForTooltip.after(tooltipEl);

            window.addEventListener("optimizedResize", function () {
              tooltipEl.style.top = (elemForTooltip.offsetTop - 8) + 'px';
              tooltipEl.style.left = (elemForTooltip.offsetLeft + elemForTooltip.clientWidth - 8) + 'px';
              tooltipEl.style.right = 'auto';
            });

            document.addEventListener("updateTooltips", function () {
              tooltipEl.style.top = (elemForTooltip.offsetTop - 8) + 'px';
              tooltipEl.style.left = (elemForTooltip.offsetLeft + elemForTooltip.clientWidth - 8) + 'px';
              tooltipEl.style.right = 'auto';
            });
          } else {
            elemForTooltip.append(tooltipEl);
          }

          let tooltipTriggerEl = tooltipEl.querySelector('[data-bs-toggle="tooltip"]');
          let tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
            container: tooltipTriggerEl,
            placement: placement,
            trigger: tooltipTrigger
                ? tooltipTrigger
                : 'hover focus',
            template: template,
            sanitize: false,
          });

          $(document).on('click', function (e) {
            const $target = $(e.target).closest('.tooltip-icon-wrap');

            if ($target.length <= 0 && !$(e.target).hasClass('tooltip-icon-wrap')) {
              tooltip.hide();
            }
          });
        });
      }
    }
  }
};

export const initHotspots = function () {
  if (typeof bootstrap === 'undefined' || typeof jsAppData === 'undefined') {
    return;
  }

  const lottiePath = '/img/lottie/pulsing-circle-blue1.json';
  const deviceType = $('[data-device-type]').data('device-type');

  if (typeof jsAppHotspots !== 'undefined') {
    for (let key in jsAppHotspots) {
      let jsAppHotspot = jsAppHotspots[key];

      if (jsAppHotspot.tooltip_title && typeof jsAppHotspot.tooltip_title.replaceAll === 'function') {
        jsAppHotspot.tooltip_title = jsAppHotspot.tooltip_title.replaceAll('"', "'");
      }

      if (jsAppHotspot.tooltip_desc && typeof jsAppHotspot.tooltip_desc.replaceAll === 'function') {
        jsAppHotspot.tooltip_desc = jsAppHotspot.tooltip_desc.replaceAll('"', "'");
      }

      let elemsForHotspots = {};
      elemsForHotspots = document.querySelectorAll(jsAppHotspot.selector);

      if (elemsForHotspots) {
        let placement = 'auto';
        let tooltipTrigger = (deviceType === 'phone' || deviceType === 'tablet' ? 'click' : 'click');
        let template = `<div class="tooltip dark" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`;

        let tooltipHtml = '';
        tooltipHtml += `<span class="hotspot" data-bs-toggle="hotspot" data-bs-html="true" data-bs-title="<div class='title'>${jsAppHotspot.tooltip_title}</div>${jsAppHotspot.tooltip_desc}">`;
        tooltipHtml += `  <div class="lottie-animation"></div>`;
        tooltipHtml += `</span>`;

        [...elemsForHotspots].map(function (elemForHotspots) {
          if (elemForHotspots.style.display === 'none') {
            return;
          }

          if (typeof elemForHotspots.dataset !== 'undefined' && elemForHotspots.dataset.tooltipStatus === 'hide') {
            return;
          }

          let tooltipEl = document.createElement('div');
          tooltipEl.className = 'hotspot-icon-wrap type-default';
          tooltipEl.innerHTML = tooltipHtml;

          elemForHotspots.append(tooltipEl);

          let tooltipTriggerEl = tooltipEl.querySelector('[data-bs-toggle="hotspot"]');
          let tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
            container: tooltipTriggerEl,
            placement: placement,
            trigger: tooltipTrigger
                ? tooltipTrigger
                : 'hover focus',
            template: template,
            sanitize: false,
          });

          const hotspotIconEl = tooltipEl.querySelector('[data-bs-toggle="hotspot"] .lottie-animation');
          if (hotspotIconEl) {
            lottie.loadAnimation({
              container: hotspotIconEl,
              renderer: 'svg',  // svg, canvas, html
              loop: true,
              autoplay: true,
              path: lottiePath,
              // animationData: animationData
            });

            axios.patch(route('api.hotspots.set_viewed_by_user'), {"hotspotId": jsAppHotspot.hotspot_id, "page": jsAppHotspot.page}).
                then(function(response) {
                  let data = (response.data ? response.data : {});
                }).
                catch(function(error) {
                  // console.log(error);
                }).
                finally(() => {
                });
          }
        });

        $('.hotspot-icon-wrap .tooltip').on('click', function() {
          $(this).closest('.hotspot-icon-wrap').remove();
        });

        $('.hotspot-icon-wrap').on('click', function(e) {
          e.stopPropagation();

          $(this).find('.lottie-animation').hide();

          $('.hotspot-icon-wrap .tooltip').each(function (index, el) {
            $(el).closest('.hotspot-icon-wrap').remove();
          });
        });

        $(document).on('click', function(e) {
          const $hotspotWrap = $(e.target).closest('.hotspot-icon-wrap');
          const $hotspot = $hotspotWrap.find('.tooltip');

          $('.hotspot-icon-wrap .tooltip').each(function (index, el) {
            $(el).closest('.hotspot-icon-wrap').remove();
          });

          if ($hotspot.length > 0) {
            $hotspotWrap.remove();
          }
        });
      }
    }
  }
};

export const capitalizeFirstLetter = function(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function addToCart(planOptionId, quantity, quantityX, needReplace = false, source = '') {
  if (isProcessingAddingToCart) {
    return;
  }
  isProcessingAddingToCart = true;

  let $errorBox = $('.error-box');

  $('.payment-order').trigger('adding-to-cart');
  $errorBox.html('').hide();

  let data = {
    id: planOptionId,
    quantity: quantity,
    quantityX: quantityX,
    needReplace: needReplace ? 1 : 0
  };

  $.ajax({
    url: route('api.cart.add'),
    method: 'POST',
    data: data,
    dataType: 'json',
    success: function (response) {
      if (!response.success && response.message) {
        $errorBox.html(response.message).show();
      }
    },
    error: function () {
      $errorBox.html('An error occurred while adding to cart').show();
    },
    complete: function (response) {
      updatePaymentOrder('', source);
      isProcessingAddingToCart = false;
    }
  });
}

export function plusMinusToCart() {
  let $cartListInputNumber = $('.cart-list__plus-minus .input-number');
  let $cartListBtnNumber = $('.cart-list__plus-minus .btn-number');

  $cartListBtnNumber.on('click', function(e) {
    e.preventDefault();

    if (isProcessingAddingToCart) {
      return;
    }

    let fieldName = $(this).data('field');
    let type = $(this).data('type');
    let input = $(this).closest('.cart-list__plus-minus').find('input[name="' + fieldName + '"]');
    let currentVal = parseInt(input.val());
    let step = parseInt(input.attr('step'));
    let trafficType = input.data('traffic-type');

    if (!isNaN(currentVal)) {
      if (type === 'minus') {
        if (trafficType === 'website-traffic') {
          if (currentVal <= 100) {
            step = 10;
          } else if (currentVal <= 1000) {
            step = 100;
          } else if (currentVal <= 10000) {
            step = 1000;
          } else if (currentVal <= 100000) {
            step = 10000;
          } else if (currentVal <= 1000000) {
            step = 100000;
          } else if (currentVal <= 10000000) {
            step = 1000000;
          }
        }

        if (currentVal > parseInt(input.attr('min'))) {
          input.val(currentVal - step).change();
        }
        if (parseInt(input.val()) === parseInt(input.attr('min'))) {
          $(this).prop('disabled', true);
        }
      } else if (type === 'plus') {
        if (trafficType === 'website-traffic') {
          if (currentVal >= 1000000) {
            step = 1000000;
          } else if (currentVal >= 100000) {
            step = 100000;
          } else if (currentVal >= 10000) {
            step = 10000;
          } else if (currentVal >= 1000) {
            step = 1000;
          } else if (currentVal >= 100) {
            step = 100;
          } else if (currentVal >= 10) {
            step = 10;
          }
        }

        if (currentVal < parseInt(input.attr('max'))) {
          input.val(currentVal + step).change();
        }
        if (parseInt(input.val()) === parseInt(input.attr('max'))) {
          $(this).prop('disabled', true);
        }
      }
    } else {
      input.val(0);
    }
  });

  $cartListInputNumber.on('focusin', function() {
    $(this).data('oldValue', $(this).val());
  });

  $cartListInputNumber.on('change', function() {
    if (isProcessingAddingToCart) {
      return;
    }

    let minVal = parseInt($(this).attr('min'));
    let maxVal = parseInt($(this).attr('max'));
    let currentVal = parseInt($(this).val());
    let step = parseInt($(this).attr('step'));
    let name = $(this).attr('name');
    let needUpdateCart = true;
    let trafficType = $(this).data('traffic-type');

    if (currentVal >= minVal) {
      $('.cart-list__plus-minus btn-number[data-type="minus"][data-field="' + name + '"]').
          prop('disabled', false);
    } else {
      $(this).val(minVal);
    }

    if (currentVal <= maxVal) {
      $(".cart-list__plus-minus .btn-number[data-type='plus'][data-field='" + name + "']").
          prop('disabled', false);
    } else {
      $(this).val(maxVal);
    }

    if ($(this).val() === $(this).data('oldValue')) {
      needUpdateCart = false;
    }

    if ($(this).val() > step) {
      $('.cart-list__plus-minus .btn-number[data-type="minus"]').prop('disabled', false);
    } else {
      $('.cart-list__plus-minus .btn-number[data-type="minus"]').prop('disabled', true);
    }

    clearTimeout(addToCartTimeout);
    if (needUpdateCart) {
      addToCartTimeout = setTimeout(() => {
        addToCart($(this).data('plan-option-id'), $(this).val(), 1, true);
      }, 1000);
    }
  });

  $cartListInputNumber.on('keydown', function(e) {
    if (isProcessingAddingToCart) {
      return;
    }

    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
        (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  });
}

export function updateCountItemInCart() {
  $.ajax({
    url: '/api/cart/items-in-cart',
    method: 'GET',
    data: {},
    dataType: 'json',
    success: function(response) {
      let data = (response?.data ? response.data : []);

      if (response?.success) {
        $('.top-cart-counter').addClass('show').text(data.itemsInCart);

        if (parseInt(data.itemsInCart) > 0) {
          $('.top-cart-button').removeClass('disabled');
        } else {
          $('.top-cart-button').addClass('disabled');
        }
      }
    },
  });
}

export function updatePaymentOrder(orderId = '', source = '', isEasy = false) {
  let currentUrl = window.location.href;
  let amountToPay = 0;
  let vatRate = 0;
  let vatSumm = 0;
  let currency = 'USD';

  $('.payment-order').trigger('cart-updating');

  let params = {
    'currentUrl': currentUrl,
    'orderId': orderId,
  };

  axios.get(route('api.cart.payment_order'), {params: params}).
      then(function(response) {
        let data = (response.data ? response.data : {});

        if (data.success) {
          let orderHtml = data.data.orderHtml;
          amountToPay = parseFloat(data.data.amountToPay ?? 0);
          vatRate = parseFloat(data.data.vatRate ?? 0);
          vatSumm = parseFloat(data.data.vatSumm ?? 0);
          currency = data.data.currency ?? 'USD';

          $('.payment-order').html(orderHtml).trigger('cart-updated-success');

          if (orderHtml && amountToPay > 0) {
            $('.payment-options .tab-content').removeClass('disabled');

            $('.payment-options [data-amount-limit]').each(function(index, elem) {
              let amountLimit = parseInt($(elem).data('amount-limit'));
              if (amountLimit === -1 || amountLimit > amountToPay) {
                $(elem).removeClass('disabled');
              } else {
                $(elem).addClass('disabled');
              }
            });

            if (data.data.allowedCashback) {
              $('.cashback-payment').show();
            } else {
              $('.cashback-payment').hide();
            }
          } else {
            $('.payment-options .tab-content').addClass('disabled');
          }
        }
      }).
      catch(function(error) {
        $('.payment-order').trigger('cart-updated-error');
      }).
      finally(() => {
        $('.payment-order').trigger('cart-updated-complete', [{
          source: source,
          isEasy: isEasy,
          amountToPay: amountToPay,
          currency: currency,
          vatRate: vatRate,
          vatSumm: vatSumm,
        }]);
      });
}

export function updateCartWidget(isEasy = false) {
  const updateFastSpringSession = () => {
    let params = '';

    fetch(route('api.fastspring.get_session_data') + params, {
      method: 'GET',
    }).
        then(sessionResponse => {
          if (!sessionResponse.ok) {
            throw new Error('HTTP error ' + sessionResponse.status);
          }
          $('body').removeClass('loading');
          return sessionResponse.json();
        }).
        then(session => {
          if (fastspring) {
            fastspring.builder.secure(session.securePayload, session.secureKey);
            fastspring.builder.checkout(session.id);
          }

          checkOrderStatus(parseInt(session.orderId));
          $('[data-pay-by="fastspring"]').prop('disabled', false);
          $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
          $('body').removeClass('loading');
        }).
        catch(error => {
          console.log('There was an error:', error);
          $('[data-pay-by="fastspring"]').prop('disabled', false);
          $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
          $('body').removeClass('loading');
        });
  };

  let currentUrl = window.location.href;
  let $cartWidgetWrap = $('.cart-widget-wrap');

  $('.cart-widget').trigger('cart-updating');

  let params = {
    'currentUrl': currentUrl,
  };

  axios.get(route('api.cart.widget'), {params: params}).
      then(function(response) {
        let data = (response.data ? response.data : {});

        if (data.success) {
          let cartHtml = data.data.cartHtml;

          let amountToPay = parseInt(data.data.amountToPay ?? 0);
          if (amountToPay <= 0) {
            return;
          }

          $('.cart-widget-wrap').html(cartHtml);
          $('.cart-widget').trigger('cart-updated-success');

          $('[data-pay-by="fastspring-popup"]').off('click').on('click', function() {
            $(this).prop('disabled', true);

            if ($('.fastspring-iframe-popup-wrap').length > 0) {
              $('.fastspring-iframe-wrap').empty();
              $('.fastspring-iframe-popup-wrap').empty();
              $('.fastspring-iframe-popup-wrap').
                  html(`<script id="fsc-api" src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js" type="text/javascript" data-storefront="${jsAppData.fastspringPopupStorefront}" data-access-key="${jsAppData.fastspringAccessKey}" data-debug="false"></script>`);

              $('[data-pay-by="fastspring"]').prop('disabled', false).show();

              updateFastSpringSession();
            }
          });

          $('.cart-widget').on('click', function(e) {
            if (!$(e.target).hasClass('cart-widget__button') && $(e.target).closest('.cart-widget__button').length <= 0) {
              document.location.href = route('cart') + '#payments';
            }
          });
        }
      }).
      catch(function(error) {
        $('.cart-widget').trigger('cart-updated-error');
      }).
      finally(() => {
        $('.cart-widget').trigger('cart-updated-complete', [{isEasy: isEasy}]);
      });
}

export function updatePaymentMethods() {
  axios.get(route('api.cart.payment_methods')).
      then(function(response) {
        let data = (response.data ? response.data : {});
      }).
      catch(function(error) {
        // console.log(error);
      }).
      finally(() => {
      });
}

export function updateCoupon() {
  let currentUrl = window.location.href;

  $('#apply_coupon').prop('disabled', true);

  $.ajax({
    url: '/api/apply-coupon-old',
    method: 'POST',
    data: {
      coupon_code: $('#coupon_code').val(),
    },
    dataType: 'json',
    success: function() {
      updatePaymentOrder();
      // $('#apply_coupon').prop('disabled', false);
    },
    error: function() {
      $('#apply_coupon').prop('disabled', false);
    },
  });
}

export function updateVatId() {
  let currentUrl = window.location.href;

  $('#apply-vat-id').prop('disabled', true);

  $.ajax({
    url: '/api/apply-vat-id',
    method: 'POST',
    data: {
      vatId: $('#vat_id').val(),
    },
    success: function(response) {
      if (response.success) {
        $('.vat-block .info-message-input').hide();
        $('.vat-block .error-message-input__desc').html('');

        updatePaymentOrder();
      } else {
        $('.vat-block .info-message-input').show();
        $('.vat-block .error-message-input__desc').
            html(response.message);
      }

      $('#apply-vat-id').prop('disabled', false);
    },
    error: function() {
      $('#apply-vat-id').prop('disabled', false);
    },
  });
}

export const getExchangeRate = async (currency) => {
  if (exchangeRate) {
    return exchangeRate;
  }

  exchangeRate = axios.get(route('api.cart.get_exchange_rate', [currency]));

  return exchangeRate;
};

let checkOrderStatusTimeout = null;
export const checkOrderStatus = (orderId, paymentMethod = '') => {
  function displayIframe3dVerification(isShow = true) {
    if (isShow) {
      $('.iframe-3d-verification').show();
      $('.form-payment-card').hide();
    } else {
      $('.iframe-3d-verification').hide();
      $('.form-payment-card').show();
    }
  }

  function showCheckoutErrorBlock() {
    $('.payment-options').show();
    if (paymentMethod) {
      $('.checkout-block-payment[data-payment-method="' + paymentMethod + '"]').hide();
      $('.checkout-block-info[data-payment-method="' + paymentMethod + '"]').addClass('error');
    } else {
      $('.checkout-block-payment').hide();
      $('.checkout-block-info').addClass('error');
    }
    $('.checkout-block-qr-code').hide();
    displayIframe3dVerification(false);
    $('.loading-block').hide();

    $('.checkout-block-info [data-button-action="repeat"]').
        off('click').
        on('click', function() {
          $(this).prop('disabled', true);

          $('.checkout-block-payment').show();
          $('.checkout-block-info').removeClass('error').removeClass('success');
        });

    if (typeof window.Intercom === "function") {
      window.Intercom('showNewMessage', 'I need help with my payment');
    }
  }

  function showCheckoutSuccessBlock() {
    $('.payment-options').hide();
    $('.checkout-block-payment').hide();
    $('.checkout-block-info').addClass('success');
    $('.checkout-block-qr-code').hide();
    displayIframe3dVerification(false);
    $('.loading-block').hide();
  }

  $('.payment-order').trigger('order-checking');
  clearTimeout(checkOrderStatusTimeout);

  if (orderId) {
    axios.get(route('api.order.get_status', [orderId])).
        then(function(response) {
          let data = (response.data ? response.data : {});

          if (data.status) {
            if (data.status === 'pending') {
              checkOrderStatusTimeout = setTimeout(function() {
                checkOrderStatus(orderId, paymentMethod);
              }, 1000);
              $('.payment-order').trigger('order-pending');
            } else if (data.status === 'paid') {
              showCheckoutSuccessBlock();
              updatePaymentOrder(null, null, true);
              $('.payment-order').trigger('order-paid');
            } else if (data.status === 'declined') {
              showCheckoutErrorBlock();
              $('.payment-order').trigger('order-declined');
            } else {
              showCheckoutErrorBlock();
              $('.payment-order').trigger('order-declined');
            }
          } else {
            checkOrderStatusTimeout = setTimeout(function() {
              checkOrderStatus(orderId, paymentMethod);
            }, 1000);
          }
        }).
        catch(function(error) {
          // console.log(error);
          checkOrderStatusTimeout = setTimeout(function() {
            checkOrderStatus(orderId, paymentMethod);
          }, 5000);
        });
  }
};

export const setUserAgent = (window, userAgent) => {
  if (window.navigator.userAgent !== userAgent) {
    var userAgentProp = {
      get: function() {
        return userAgent;
      },
    };
    try {
      Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
    } catch (e) {
      window.navigator = Object.create(navigator, {
        userAgent: userAgentProp,
      });
    }
  }
};

export const validateEmail = (email) => {
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegExp.test(String(email).toLowerCase());
};

export const showCaptcha = ($button) => {
  if (typeof turnstile === 'undefined') {
    $button.prop('disabled', false);
    return true;
  }

  try {
    turnstile.remove('#cfCaptcha');

    turnstile.render('#cfCaptcha', {
      "sitekey": '0x4AAAAAAAdnTjZ62aUnGzs8',
      "callback": function(token) {
        $button.prop('disabled', false);
      },
      "error-callback": function(error) {
        console.log('turnstile error: ' + error);
      },
    });
  } catch (e) {
    console.log('Error turnstile: ' + e.responseText);
    $button.prop('disabled', false);
  }
};