import {formatTimeleft, formValidationErrorClear, formValidationErrorHandling, getGaClientId, runIfHasSelecter} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';
import {hideFormError, insertHtmlForDataJs, showFormError} from './index.js';

const initScript = () => {
    $('[data-button-action="show-auth-popup-registration"]').on('click', function() {
        let $popup = $('[data-role="auth-popup-registration"]');
        let $popupWrap = $popup.closest('.auth-popup-wrap');
        if ($popupWrap.length > 0) {
            $popupWrap.addClass('show');
        }
    });

    let $form = $('[data-form="form-auth-popup__registration"]');
    let $formInfo = $form.find('[data-form-info]');
    let $formConfirmationCode = $('[data-form="form-auth-popup__confirmation-code"]');

    let gaClientId = getGaClientId();
    $form.find('[name="cid_field"]').val(gaClientId);

    let timerExpiresResendCodeTimeout = null;
    const startTimerExpiresResendCode = () => {
        let secondsPassed = 0;
        clearTimeout(timerExpiresResendCodeTimeout);

        const waitTimer = () => {
            secondsPassed++;

            if (secondsPassed < 30) {
                $('.auth-popup [data-js="resend-code-in"]').text(30 - secondsPassed);
                timerExpiresResendCodeTimeout = setTimeout(function() {
                    waitTimer();
                }, 1000);
            } else {
                clearTimeout(timerExpiresResendCodeTimeout);
                secondsPassed = 0;
                $('.auth-popup .auth-popup__resend-code-in').hide();
                $('.auth-popup .button-resend-code').prop('disabled', false).show();
            }
        };
        waitTimer();
    }

    $('.auth-popup [data-button-action="auth-email"]').on('click', function () {
        $('.auth-popup__role').hide();
        $('.auth-popup [data-form-info]').html('').hide();
        $('.auth-popup [data-form-error]').html('').hide();
        $('.auth-popup button').prop('disabled', false);
        $('.auth-popup .confirmation-code__fields input').val('');
        $('.auth-popup__role[data-role="auth-popup-registration"]').show();
        $('.auth-popup .invalid-feedback').remove();
        $('.auth-popup input[type="text"]').val('').removeClass('valid invalid');
    });

    $form.find('input').on('input', function() {
        if ($(this).val().trim() !== '') {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

    $form.find('input').each(function() {
        if ($(this).val().trim() !== '') {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

    $form.find('[data-button-action="submit"]').on('click', function (e) {
        e.preventDefault();

        $form.submit();
    });

    $form.on('submit', function (e) {
        e.preventDefault();
        let $buttonSend = $(this).find('[data-button-action="submit"]');

        let type = $form.find('input[name="type"]').val();
        let email = $form.find('input[name="email"]').val().trim().toLowerCase();
        let phone = $form.find('input[name="phone"]').val().trim();
        let phoneDialCode = $form.find('input[name="phone_dial_code"]').val();
        let phoneCountryCode = $form.find('input[name="phone_country_code"]').val();

        let $urlEl = $('input[name="url"]');
        if ($urlEl.length <= 0) {
            $urlEl = $('input[name="website"]');
        }
        let url = ($urlEl.length > 0 ? $urlEl.val().trim().toLowerCase() : '');
        let keyword = $('input[name="keyword"]').val();

        let data = {
            type: type ?? '',
            email: email ?? '',
            phone: phone ?? '',
            phone_dial_code: phoneDialCode ?? '',
            phone_country_code: phoneCountryCode ?? '',
            url: url,
            website: url,
            keyword: keyword ?? ''
        };

        formValidationErrorClear($form);
        hideFormError($form);
        $formInfo.html('').hide();
        $buttonSend.prop('disabled', true);

        $('.auth-popup .auth-popup__resend-code-in').show();
        $('.auth-popup .button-resend-code').prop('disabled', true).hide();

        startTimerExpiresResendCode();

        if (type === 'phone' || type === 'whatsapp') {
            insertHtmlForDataJs('phone', phone);

            axios.post(route('api.v1.auth.twilio.send_confirmation_code'), data).
                then(response => {
                    let data = (response?.data ? response.data : {});

                    if (data.errors) {
                        formValidationErrorHandling($form, data.errors);
                        $buttonSend.prop('disabled', false);
                        return;
                    }

                    if (data.message) {
                        showFormError($form, data.message);
                        showFormError($formConfirmationCode, data.message);
                    }

                    $('[data-role="auth-popup-registration"]').hide();
                    $('[data-role="auth-popup-confirmation-code"]').show();

                    $buttonSend.prop('disabled', false);
                }).
                catch(error => {
                    let data = (error?.response?.data ? error.response.data : {});

                    if (data.errors) {
                        formValidationErrorHandling($form, data.errors);
                    }

                    if (data.message) {
                        showFormError($form, data.message);
                        showFormError($formConfirmationCode, data.message);
                    }

                    $buttonSend.prop('disabled', false);
                });
        } else {
            insertHtmlForDataJs('email', email);

            $formInfo.html('Please follow the link in the email sent to your email address <b>' + email + '</b>').show();
            $('[data-role="auth-popup-registration"]').hide();
            $('[data-role="auth-popup-confirmation-code"]').show();
            startTimerExpiresResendCode();

            axios.post(route('api.v1.auth.popup.registration'), data).
                then(response => {
                    let data = (response?.data ? response.data : {});
                    if (!data) {
                        $formInfo.html('').hide();
                        $('[data-role="auth-popup-registration"]').show();
                        $('[data-role="auth-popup-confirmation-code"]').hide();
                        $buttonSend.prop('disabled', false);

                        return;
                    }

                    if (data.errors) {
                        $formInfo.html('').hide();
                        $('[data-role="auth-popup-registration"]').show();
                        $('[data-role="auth-popup-confirmation-code"]').hide();
                        formValidationErrorHandling($form, data.errors);
                        $buttonSend.prop('disabled', false);
                        return;
                    }

                    if (data.message) {
                        return;
                    }

                    let userId = parseInt(data.data.userId ?? 0);
                    if (userId) {
                        $formInfo.html('Please follow the link in the email sent to your email address <b>' + email + '</b>').show();
                        $('[data-role="auth-popup-registration"]').hide();
                        $('[data-role="auth-popup-confirmation-code"]').show();
                        $('[data-role="auth-popup-confirmation-code"] [name="user_id"]').val(userId);
                        startTimerExpiresResendCode();
                    }

                    $buttonSend.prop('disabled', false);
                }).
                catch(error => {
                    let data = error?.response?.data ? error.response.data : {};
                    let errors = data.errors ? data.errors : {};

                    $formInfo.html('').hide();
                    $('[data-role="auth-popup-registration"]').show();
                    $('[data-role="auth-popup-confirmation-code"]').hide();
                    $buttonSend.prop('disabled', false);

                    if (errors.errorsForm) {
                        formValidationErrorHandling($form, errors.errorsForm);
                    }

                    if (errors.message && errors.message.indexOf('already exists') !== -1) {
                        // if (!errors.isActivated && errors.userId) {
                        //     $formInfo.html('Please follow the link in the email sent to your email address <b>' + email + '</b>').show();
                        //     $('[data-role="auth-popup-registration"]').hide();
                        //     $('[data-role="auth-popup-confirmation-code"]').show();
                        //     $('[data-role="auth-popup-confirmation-code"] [name="user_id"]').val(errors.userId);
                        //     $('[data-button-action="resend-code"]').trigger('click');
                        // } else {
                        //     $('.auth-popup__role').hide();
                        //     $('.auth-popup [data-form-info]').html('').hide();
                        //     $('.auth-popup [data-form-error]').html('').hide();
                        //     $('.auth-popup button').prop('disabled', false);
                        //     $('.auth-popup .confirmation-code__fields input').val('');
                        //     $('.auth-popup__role[data-role="auth-popup-login"]').show();
                        // }

                        if (errors.userId) {
                            $formInfo.html('Please follow the link in the email sent to your email address <b>' + email + '</b>').show();
                            $('[data-role="auth-popup-registration"]').hide();
                            $('[data-role="auth-popup-confirmation-code"]').show();
                            $('[data-role="auth-popup-confirmation-code"] [name="user_id"]').val(errors.userId);
                            $('[data-button-action="resend-code"]').trigger('click');
                        } else {
                            $('.auth-popup__role').hide();
                            $('.auth-popup [data-form-info]').html('').hide();
                            $('.auth-popup [data-form-error]').html('').hide();
                            $('.auth-popup button').prop('disabled', false);
                            $('.auth-popup .confirmation-code__fields input').val('');
                            $('.auth-popup__role[data-role="auth-popup-login"]').show();
                        }
                    }
                });
        }
    });
}

export const initAuthPopupRegistrationScripts = function () {
    runIfHasSelecter('[data-role="auth-popup-registration"]', [
        initScript
    ]);
}
