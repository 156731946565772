import {formValidationErrorHandling, getGaClientId, runIfHasSelecter} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';

const initScript = () => {
    $('[data-button-action="show-auth-popup-login"]').on('click', function() {
        $('.auth-popup__role').hide();

        let $popup = $('[data-role="auth-popup-registration"]').show();
        let $popupWrap = $popup.closest('.auth-popup-wrap');
        if ($popupWrap.length > 0) {
            $popupWrap.addClass('show');
        }
    });

    let $form = $('[data-form="form-auth-popup__login"]');
    let $formError = $form.find('[data-form-error]');

    $form.find('input').on('input', function() {
        if ($(this).val().trim() !== '') {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

    $form.find('input').each(function() {
        if ($(this).val().trim() !== '') {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

    $form.find('[data-button-action="submit"]').on('click', function (e) {
        e.preventDefault();

        $form.submit();
    });

    $form.on('submit', function (e) {
        e.preventDefault();
        let $buttonSend = $(this).find('[data-button-action="submit"]');

        let data = {
            email: $form.find('input[name="email"]').val().trim().toLowerCase(),
            password: $form.find('input[name="password"]').val(),
        };

        $formError.html('');
        $buttonSend.prop('disabled', true);

        axios.post(route('api.v1.auth.popup.login'), data).
            then(response => {
                let data = (response?.data ? response.data : {});
                if (!data) {
                    $buttonSend.prop('disabled', false);
                    return;
                }

                if (data.errors) {
                    formValidationErrorHandling($form, data.errors);
                    $buttonSend.prop('disabled', false);
                    return;
                }

                window.location.reload()
                // $('.auth-popup__role').hide();
                // $buttonSend.prop('disabled', false);
            }).
            catch(error => {
                let data = (error?.response?.data ? error.response.data : {});

                if (data.errors) {
                    formValidationErrorHandling($form, data.errors);
                }

                if (data.message) {
                    $formError.html(data.message).show();
                }

                $buttonSend.prop('disabled', false);
            });
    });

    // Show/Hide password
    $('.icon-showhide-password').on('click', function () {
        let $inputPassword = $(this).prev('input[data-showhide]');

        if ($inputPassword.attr('type') === 'password') {
            $inputPassword.attr('type', 'text');
        } else {
            $inputPassword.attr('type', 'password');
        }
    });
}

export const initAuthPopupLoginScripts = function () {
    runIfHasSelecter('[data-role="auth-popup-login"]', [
        initScript
    ]);
}
