import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initCommon = function() {
    let $action = $('[data-role="order-action"]');
    if ($action.length === 0) {
        return;
    }

    let timeleft = $action.data('action-timeleft');
    let secondsPassed = 0;
    let isPaid = false;
    let orderId = jsPageData.orderId ?? null;

    function periodicall() {
        secondsPassed++;

        let distance = timeleft - secondsPassed;
        let days = Math.floor(distance / 86400);
        let hours = Math.floor(distance / 3600) - days * 24;
        let minutes = Math.floor((distance % 3600) / 60);
        let seconds = Math.floor(distance % 60);

        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;

        let demoEl = $('.skills-graph-circle__count');
        if (demoEl.length > 0) {
            demoEl.html('<span>' + minutes + '</span>:' + '<span>' + seconds + '</span>');
        }

        let skillEl = $('.skills-graph-circle__svg-fill');
        if (skillEl.length > 0) {
            let stepCircle = 150.79644737231007 / 1800;
            let strokeDashoffset = (stepCircle * (1800 - distance)) * -1;
            skillEl.find('circle').attr('stroke-dashoffset', strokeDashoffset);
        }

        if (isPaid) {
            return false;
        }

        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            showErrorBlock();
            return false;
        } else {
            setTimeout(periodicall, 1000);
        }
    }

    function checkOrder() {
        axios.get(route('api.orders.check-status', orderId)).
            then(function(response) {
                let data = (response.data ? response.data : {});

                if (data && data.success) {
                    if (data.data.status === 'pending') {
                        setTimeout(checkOrder, 1000);
                    } else if (data.data.status === 'paid') {
                        showSuccessBlock();
                    } else {
                        showErrorBlock();
                    }
                } else {
                    setTimeout(checkOrder, 1000);
                }
            }).
            catch(function(error) {
                // document.location.href = route('cart');
            });
    }

    function showErrorBlock() {
        $('.crypto-payment__content').hide();
        $('.crypto-payment__error').show();
        $('.crypto-payment__success').hide();
        $('[data-button-action="cancel"]').hide();
        $('[data-button-action="repeat"]').show();
        $('[data-button-action="create-project"]').hide();
    }

    function showSuccessBlock() {
        $('.crypto-payment__content').hide();
        $('.crypto-payment__error').hide();
        $('.crypto-payment__success').show();
        $('[data-button-action="cancel"]').hide();
        $('[data-button-action="repeat"]').hide();
        $('[data-button-action="create-project"]').show();
    }

    $('[data-button-action="cancel"]').on('click', function() {
        let $button = $(this);

        $button.prop('disabled', true);

        axios.patch(route('api.orders.cancel', orderId)).
            then(function(response) {
                let data = (response.data ? response.data : {});
                document.location.href = route('cart');
            }).
            catch(function(error) {
                document.location.href = route('cart');
            });
    });

    $('[data-button-action="repeat"]').on('click', function() {
        $(this).prop('disabled', true);
        document.location.reload();
    });

    periodicall();
    checkOrder();
};

const initGenerateQrCode = function() {
  let generatedQrCode = document.getElementById("generated_qr_code");
  if (generatedQrCode) {
    var qrcode = new QRCode(generatedQrCode, {
      text: $('#sp_address_wallet').text(),
      width: 220,
      height: 220,
      colorDark : "#000000",
      colorLight : "#ffffff",
      correctLevel : QRCode.CorrectLevel.H
    });
  }
}

export const initCartDoPayScripts = () => {
    runIfHasSelecter('[data-role="cart-do-pay"]', [
        initCommon,
        initGenerateQrCode,
    ]);
};
