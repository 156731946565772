import {
  formValidationErrorClear,
  formValidationErrorHandling,
  runIfHasSelecter,
} from '../../utils/panel-lib.js';
import route from '../index.js';

const initForms = function() {
  const updateFormFields = function($form) {
    $form.find('input, select, textarea').each(function(index, el) {
      if ($(el).attr('type') === 'checkbox') {
        $(el).data('val', $(el).prop('checked'));
      } else {
        $(el).data('val', $(el).val());
      }
    });
  };

  const getFormFields = function($form) {
    let data = {};

    $form.find('input, select, textarea').each(function(index, el) {
      let fieldName = $(el).attr('name');
      if (fieldName) {
        fieldName = fieldName.replace('[]', '');

        if ($(el).attr('type') === 'checkbox') {
          data[fieldName] = ($(el).prop('checked') ? 1 : 0);
        } else {
          data[fieldName] = $(el).val();
        }
      }
    });

    return data;
  };

  let $forms = $('[data-role="billing-settings"] form[data-form-id]');

  if ($forms.length === 0) {
    return;
  }

  $forms.each(function() {
    let $form = $(this);
    let $saveButton = $form.find('[data-ajax-action="save-form"]');
    let isChangesInputs = [];

    updateFormFields($form);

    $form.find('input, select, textarea').on('input change', function() {
      let isChanges = false;

      formValidationErrorClear($form);

      if ($(this).attr('type') === 'checkbox') {
        isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
      } else {
        isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
      }

      for (var key in isChangesInputs) {
        if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
          isChanges = true;

          break;
        }
      }

      if (isChanges) {
        $saveButton.find('.button-text').html('Save Changes');
        $saveButton.find('.icon-from-button').hide();
        $saveButton.prop('disabled', false);
      } else {
        $saveButton.find('.button-text').html('Saved');
        $saveButton.find('.icon-from-button').show();
        $saveButton.prop('disabled', true);
      }
    });

    $saveButton.on('click', function(e) {
      e.preventDefault();

      let $button = $(this);
      let $form = $button.closest('form');

      let data = {};
      $form.find('[name]').each(function(index, elem) {
        data[$(elem).attr('name')] = $(elem).val();
      });

      let dataSave = getFormFields($form);
      data = {...data, ...dataSave};

      $button.find('.button-text').html('Saving...');
      $button.find('.icon-from-button').hide();
      $button.prop('disabled', true);

      axios.patch(route('api.billing.settings.update'), data).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data && data.success) {
              $button.find('.button-text').html('Saved');
              $button.find('.icon-from-button').show();
              formValidationErrorClear($form);

              isChangesInputs = [];
              updateFormFields($form);
            } else {
              $button.find('.button-text').html('Save Changes');
              $button.find('.icon-from-button').hide();
              $button.prop('disabled', false);

              if (data.message) {
                $form.find('.error-box').
                    html(data.message).
                    show();
              }

              if (data.errors) {
                formValidationErrorHandling($form, data.errors);
              }
            }
          }).
          catch(function() {
            $form.find('.error-box').
                html('An error occurred while saving').
                show();
            $button.find('.button-text').html('Save Changes');
            $button.find('.icon-from-button').hide();
            $button.prop('disabled', false);
          });
    });
  });
};

const initManageCards = function() {
  let $deleteButton = $('[data-ajax-action="delete"]');

  $('#confirm-delete').on('show.bs.modal', function(e) {
    $(this).find('.btn-ok').data('card-id', $(e.relatedTarget).data('card-id'));
  }).find('.btn-ok').on('click', function(e) {
    e.preventDefault();

    let $button = $(this);
    let $modal = $button.closest('.modal-content');

    let cardId = $button.data('card-id');
    if (cardId) {
      cardId = cardId.trim();
    }

    let $savedCard = $('.manage-saved-cards__card[data-card-id="' + cardId + '"]');
    // let $errorBox = $savedCard.find('[data-error-box]');
    let $errorBox = $modal.find('.error-box');

    // $('.manage-saved-cards [data-error-box]').html('').hide();
    $errorBox.html('').hide();
    $button.prop('disabled', true);

    axios.delete(route('api.v1.card.delete', cardId)).
        then(function(response) {
          let data = (response.data ? response.data : {});

          if (data && data.success) {
            $modal.find('[data-bs-dismiss="modal"]').trigger('click');
            $savedCard.remove();
            if ($('.manage-saved-cards__card').length <= 0) {
              $('.manage-saved-cards').hide();
            }
          } else {
            if (data.message) {
              $errorBox.html(data.message).show();
            }
          }

          $button.prop('disabled', false);
        }).
        catch(function() {
          $errorBox.html('An error occurred while deleting the card').show();
          $button.prop('disabled', false);
        });
  });
};

const initGeoChange = function () {
  let $companyCountry = $('select[name="company_country"]');
  let $companyCity = $('select[name="company_city"]');
  let $companyState = $('select[name="company_state"]');

  function fillStates(removeAll = false) {
    if (removeAll) {
      $companyState.find('option').remove();
    } else {
      $companyState.find('option:not(:selected)').remove();
    }

    let selectedStatesIds = [];
    $companyState.find('option:selected').each(function(index, el) {
      selectedStatesIds.push(parseInt($(el).val()));
    });

    let params = {
      'countryCode': $companyCountry.val(),
    };

    axios.get(`/api/geolocations/states-by-country-code`, {
      params: params,
    }).then(function(response) {
      if (response.data && response.data.success && response.data.data) {
        let data = response.data.data;

        $companyState.prepend($('<option></option>').
            attr('value', '0').
            text('Nothing selected')
        );

        for (let i = 0, len = data.length; i < len; i++) {
          let $id = data[i]['id'];

          if (selectedStatesIds.includes($id)) {
            continue;
          }

          $companyState.append($('<option></option>').
              attr('value', $id).
              text(data[i]['name']));
        }

        $companyState.selectpicker('destroy').selectpicker();
      }
    }).catch(function(error) {
      // console.error(error);
    });
  }

  function fillCities(removeAll = false) {
    if (removeAll) {
      $companyCity.find('option').remove();
    } else {
      $companyCity.find('option:not(:selected)').remove();
    }

    let selectedCitiesIds = [];
    $companyCity.find('option:selected').each(function(index, el) {
      selectedCitiesIds.push(parseInt($(el).val()));
    });

    let params = {
      "countryCode": $companyCountry.val(),
    };

    if ($companyState.val()) {
      params.stateId = $companyState.val();
    }

    axios.get(`/api/geolocations/cities-by-country-code`, {
      params: params,
    }).then(function(response) {
      if (response.data && response.data.success && response.data.data) {
        let data = response.data.data;

        $companyCity.prepend($('<option></option>').
            attr('value', '0').
            text('Nothing selected')
        );

        for (let i = 0, len = data.length; i < len; i++) {
          let $id = data[i]['id'];

          if (selectedCitiesIds.includes($id)) {
            continue;
          }

          $companyCity.append($('<option></option>').
              attr('value', $id).
              text(data[i]['name']));
        }

        $companyCity.selectpicker('destroy').selectpicker();
      }
    }).catch(function(error) {
      // console.error(error);
    });
  }

  $companyCountry.on('change', function () {
    fillStates(true);
    fillCities(true);
  });

  $companyState.on('change', function () {
    fillCities(true);
  });

  fillStates();
  fillCities();
}

export const initBillingSettingsScripts = () => {
  runIfHasSelecter('[data-role="billing-settings"]', [
    initForms,
    initManageCards,
    // initGeoChange,
  ]);
};
