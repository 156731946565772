import {
  checkOrderStatus, getExchangeRate,
  runIfHasSelecter, setUserAgent,
} from '../../../utils/panel-lib.js';
import axios from 'axios';
import route from '../../index.js';
import {displayIframe3dVerification, hideCheckoutErrorBlock, showCheckoutErrorBlock, showCheckoutSuccessBlock} from '../index.js';

const initCommon = function()    {
  let $paymentFormWrap = $('.unlimit-checkout .form-payment-card-wrap');
  let $paymentForm = $('.unlimit-checkout form[data-form-id="form-payment-card"]');
  let $paymentFormError = $paymentForm.find('.form-payment-card__error');
  let $paymentFormsErrors = $('.form-payment-card .form-payment-card__error');
  let $buttonPay = $paymentForm.find('[data-ajax-action="pay-now"]');
  let $cart = $('.payment-order');
  let isCheckoutTopForm = $('.unlimit-checkout.top-form').length > 0;
  let $iframe3dVerification = $('.iframe-3d-verification');
  let $loadingBlock = $paymentFormWrap.find('.loading-block');
  let loadedIframe3ds = false;
  let completedCheck3ds = false;
  let orderId = jsPageData.orderId ?? null;
  let repeatOrderPayment = jsPageData.repeatOrderPayment ? 1 : 0;
  let paymentId = null;

  const createFirstRecurringPayment = (data = {}) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let $currencySel = $('select[name="unlimit_currency"]');
    data.currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';
    data.paymentSystems = ['unlimit'];

    if (jsPageData.isCartFromOrder && orderId) {
      data.orderId = orderId;
      data.repeat = repeatOrderPayment;
    }

    $('.checkout-block-info [data-button-action="repeat"]').hide();

    $buttonPay.prop('disabled', true);
    $loadingBlock.show();

    const url = (data.isSubscription === 0 ? route('api.payment.unlimit.create-payment') : route('api.payment.unlimit.create-subscription'));

    // todo temporary
    // axios.post(route('api.payment.unlimit.create-first-recurring-payment'), data).
    axios.post(url, data).
        then(response => {
          let responseData = (response.data ? response.data : {});
          if (!responseData) {
            $buttonPay.prop('disabled', false);

            return;
          }

          let redirectUrl = responseData.redirect_url ?? '';
          let status = responseData.recurring_data?.status ?? '';
          let orderId = responseData.orderId ?? null;
          let errorMessage = responseData.message ?? '';
          paymentId =  responseData.recurring_data?.id ?? null;

          if (status === 'DECLINED' || responseData.errMessage || errorMessage) {
            if (responseData.errMessage) {
              $paymentFormError.html(responseData.errMessage).show();
            } else if (errorMessage) {
              $paymentFormError.html(errorMessage).show();
            }

            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          } else if (status === 'COMPLETED') {
            showCheckoutSuccessBlock();
            $loadingBlock.hide();
          } else if (redirectUrl) {
            if (orderId) {
              checkOrderStatus(orderId, 'unlimit');
            }

            displayIframe3dVerification(true);

            $loadingBlock.show();
            $iframe3dVerification.attr('src', redirectUrl);
            $iframe3dVerification.on('load', function(){
              $loadingBlock.hide();
              loadedIframe3ds = true;
            });

            setTimeout(function() {
              if (!loadedIframe3ds) {
                displayIframe3dVerification(false);
                $iframe3dVerification.remove();
                $loadingBlock.hide();
                $paymentFormError.html('Payment failed').show();
              }
            }, 60000);
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
          $loadingBlock.hide();
        });
  };

  const createNextRecurringPayment = (data = {}) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let $currencySel = $('select[name="unlimit_currency"]');
    data.currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';
    data.paymentSystems = ['unlimit'];

    if (jsPageData.isCartFromOrder && orderId) {
      data.orderId = orderId;
      data.repeat = repeatOrderPayment;
    }

    $('.checkout-block-info [data-button-action="repeat"]').hide();
    $buttonPay.prop('disabled', true);
    $loadingBlock.show();

    axios.post(route('api.payment.unlimit.create-next-recurring-payment'), data).
        then(response => {
          let responseData = (response.data ? response.data : {});
          if (!responseData) {
            $buttonPay.prop('disabled', false);

            return;
          }

          let redirectUrl = responseData.redirect_url ?? '';
          let status = responseData.recurring_data?.status ?? '';
          let orderId = responseData.orderId ?? null;
          let errorMessage = responseData.message ?? '';
          paymentId =  responseData.recurring_data?.id ?? null;

          if (status === 'DECLINED' || responseData.errMessage || errorMessage) {
            if (responseData.errMessage) {
              $paymentFormError.html(responseData.errMessage).show();
            } else if (errorMessage) {
              $paymentFormError.html(errorMessage).show();
            }

            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          } else if (status === 'COMPLETED') {
            showCheckoutSuccessBlock();
            $loadingBlock.hide();
          } else if (responseData.message) {
            $paymentFormError.html(responseData.message).show();
            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
          $loadingBlock.hide();
        });
  };

  $paymentForm.on('submit', function(e) {
    e.preventDefault();

    $paymentFormError.html('').hide();

    const cardId = $paymentForm.find('[name="card_id"]').val();
    const cardNumber = $paymentForm.find('[name="card_number"]').val();
    const cardName = $paymentForm.find('[name="card_name"]').val();
    const cardExpirationM = $paymentForm.find('[name="card_expiration_m"]').val();
    const cardExpirationY = $paymentForm.find('[name="card_expiration_y"]').val();
    const cardCvv = $paymentForm.find('[name="card_cvv"]').val();
    const isSubscription = ($('#order_as_subscription').length > 0 ? ($('#order_as_subscription').prop('checked') ? 1 : 0) : null);

    // todo temporary
    // if (cardId && cardId !== '0') {
    //   createNextRecurringPayment({
    //     cardId: cardId,
    //     isSubscription: isSubscription,
    //   });
    //
    //   return true;
    // }

    if (!cardNumber || !cardName || !cardExpirationY || !cardExpirationY || !cardCvv) {
      return;
    }

    createFirstRecurringPayment({
      cardId: cardId,
      cardNumber: cardNumber,
      cardName: cardName,
      cardExpirationM: cardExpirationM,
      cardExpirationY: cardExpirationY,
      cardCvv: cardCvv,
      isSubscription: isSubscription
    });
  });

  $cart.on('cart-updated-complete', function(event, data) {
    let amountToPay = data.amountToPay ?? 0;
    let currency = data.currency ?? 'USD';
    if (currency !== 'USD' && currency !== 'EUR') {
      currency = 'USD';
    }
    let vatRate = parseFloat(data.vatRate ?? 0).toFixed(2);

    hideCheckoutErrorBlock('cg');
    hideCheckoutErrorBlock('unlimit');
    $paymentFormsErrors.html('').hide();

    if (isCheckoutTopForm) {
      $('.checkout-block').show();

      if ($('.payment-order__total-amount').hasClass('amount-zero')) {
        $('.checkout-block-payment[data-payment-method="unlimit"]').hide();
      } else {
        $('.checkout-block-payment[data-payment-method="unlimit"]').show();
      }
    }

    $('[data-js-amount]').html(amountToPay.toFixed(2));
    $('[data-js-currency]').html(currency);
    $('[data-js-vat]').html((vatRate * 100) + '%');

    if (currency !== 'USD') {
      getExchangeRate(currency).then(function(response) {
        if (response.data && response.data.success && response.data.data) {
          let data = response.data.data;
          let rate = data.rate ? data.rate : 1.0;
          let amountCurrency = (amountToPay * parseFloat(rate)).toFixed(2);

          $('[data-js-amount]').html(amountCurrency);
          $('[data-js-currency]').html(currency);
        }
      });
    }
  });
};

export const initCartPaymentMethodUnlimitScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
