import {formValidationErrorClear, runIfHasSelecter, showCaptcha, validateEmail} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';
import intlTelInput from 'intl-tel-input';

export const insertHtmlForDataJs = function(type, html) {
    $('.auth-popup [data-js="' + type + '"]').html(html);
};

export const showFormError = function($form, message, type = 'error') {
    let $formError = $form.find('[data-form-error]');

    $formError.removeClass('is-error is-warning').html('');
    if (message) {
        $formError.addClass(type).html(message).show();
    }
};

export const hideFormError = function($form) {
    let $formError = $form.find('[data-form-error]');

    $formError.removeClass('error warning').html('').hide();
};

export const loggingPopup = function(displayState) {
    if (!jsAppData.userUid) {
        return;
    }

    let data = {
        'state': displayState
    };

    axios.post(`/api/log/auth-popup`, data).
        then(function(response) {
            // nothing
        }).
        catch(function(error) {
            // nothing
        });
};

const initScript = () => {
    if (!jsAppData.isGuest) {
        return;
    }

    // check is demo user
    if (jsAppData.isDemoUser) {
        $('.button, button, [data-need-activation]').on('click', function(e) {
            if ($(this).data('allow') === 'demo-user') {
                return true;
            }

            e.preventDefault();
            e.stopPropagation();

            $('.auth-popup-wrap').addClass('show');
            loggingPopup('show');

            return false;
        });
    }

    $(document).on('click', function(e) {
        // show-auth-popup-registration
        let $targetButtonClick = $(e.target).closest('[data-button-action="show-auth-popup-registration"]');
        let $targetClick = $(e.target).closest('.auth-popup');
        let $targetClick2 = $(e.target).closest('.iti');

        if ($targetButtonClick.length <= 0 && $targetClick.length <= 0 && !$(e.target).hasClass('auth-popup')
            && $targetClick2.length <= 0 && !$(e.target).hasClass('iti')) {
            $('.auth-popup-wrap').removeClass('show');
            loggingPopup('hide');
            $('.auth-popup [data-form-info]').html('').hide();
            $('.auth-popup [data-form-error]').html('').hide();
        }
    });

    $('.auth-popup [data-button-action="close-popup"]').on('click', function(e) {
        $('.auth-popup-wrap').removeClass('show');
        loggingPopup('hide');
        $('.auth-popup [data-form-info]').html('').hide();
        $('.auth-popup [data-form-error]').html('').hide();
    });

    $('.auth-popup [data-button-action="change"]').on('click', function() {
        console.log('Click');
        $('.auth-popup__role').hide();
        $('.auth-popup [data-form-info]').html('').hide();
        $('.auth-popup [data-form-error]').html('').hide();
        $('.auth-popup button').prop('disabled', false);
        $('.auth-popup .confirmation-code__fields input').val('');
        $('.auth-popup__role[data-role="auth-popup-registration"]').show();
        $('.auth-popup .invalid-feedback').remove();
        $('.auth-popup input[type="text"]').val('').removeClass('valid invalid');
    });

    $('.auth-popup [data-button-action="show-login"]').on('click', function() {
        $('.auth-popup .auth-popup__separator-type').hide();
        $('.auth-popup .auth-popup__separator-type[data-type="login"]').show();
        $('.auth-popup__role').hide();
        $('.auth-popup [data-form-info]').html('').hide();
        $('.auth-popup [data-form-error]').html('').hide();
        $('.auth-popup button').prop('disabled', false);
        $('.auth-popup .confirmation-code__fields input').val('');
        $('.auth-popup__role[data-role="auth-popup-login"]').show();
        $('.auth-popup .invalid-feedback').remove();
        $('.auth-popup input[type="text"]').val('').removeClass('valid invalid');
    });

    $('.auth-popup [data-button-action="show-registration"]').on('click', function() {
        $('.auth-popup .auth-popup__srvices [data-button-action][data-type="email"]').trigger('click');
    });

    $('.auth-popup [data-button-action="resend-code"]').on('click', function(e) {
        let $button = $(this);
        let type = $('[data-form="form-auth-popup__registration"] input[name="type"]').val();
        let $wrap = $(this).closest('.confirmation-code__fields');
        $wrap.find('input[type="text"]').prop('disabled', true);
        $wrap.find('[name="confirmation_code"]').prop('disabled', true);

        $('.auth-popup [data-form-error]').removeClass('error warning').html('').hide();

        if (type === 'email') {
            let email = $('[data-form="form-auth-popup__registration"] input[name="email"]').val();

            $button.prop('disabled', true);

            axios.post(route('api.v1.auth.popup.resend_activation'), {email: email}).
                then(response => {
                    $wrap.find('input[type="text"]').val('');
                    $wrap.find('[name="confirmation_code"]').val('');
                }).
                finally(() => {
                    $wrap.find('input[type="text"]').prop('disabled', false);
                    $wrap.find('[name="confirmation_code"]').prop('disabled', false);
                    $button.prop('disabled', false);
                });
        }

        if (type === 'phone' || type === 'whatsapp') {
            $('[data-form="form-auth-popup__registration"]').submit();
        }
    });

    if (!jsAppData.isGoodIp) {
        $('[data-button-action="auth-twilio-sms"]').addClass('disabled');
        $('[data-button-action="auth-twilio-whatsapp"]').addClass('disabled');
    }

    $('.auth-popup .auth-popup__srvices [data-button-action]').on('click', function() {
        let type = $(this).data('type');
        if (!type) {
            return;
        }

        if (!jsAppData.isGoodIp && (type === 'phone' || type === 'whatsapp')) {
            return;
        }

        // $('.auth-popup .auth-popup__srvices [data-button-action]').removeClass('disabled');
        // $(this).addClass('disabled');
        $('.auth-popup .auth-popup__srvices-button').show();
        $(this).closest('.auth-popup__srvices-button').hide();

        $('.auth-popup .auth-popup__separator-type').hide();
        $('.auth-popup .auth-popup__separator-type[data-type="' + type + '"]').show();

        if (type === 'email') {
            $('.auth-popup [data-form="form-auth-popup__registration"] .form-group').hide();
            $('.auth-popup [data-form="form-auth-popup__registration"] input').prop('disabled', true);
            $('.auth-popup [data-form="form-auth-popup__registration"] .form-group__email').show();
            $('.auth-popup [data-form="form-auth-popup__registration"] input[name="email"]').prop('disabled', false);
            $('.auth-popup .confirmation-code .confirmation-code__label').hide();
            $('.auth-popup .confirmation-code .confirmation-code__label-email').show();

            $('.auth-popup [data-form="form-auth-popup__registration"] input[name="type"]').val('email');
        } else if (type === 'phone') {
            $('.auth-popup [data-form="form-auth-popup__registration"] .form-group').hide();
            $('.auth-popup [data-form="form-auth-popup__registration"] input').prop('disabled', true);
            $('.auth-popup [data-form="form-auth-popup__registration"] .form-group__phone').show();
            $('.auth-popup [data-form="form-auth-popup__registration"] input[name="phone"]').prop('disabled', false);
            $('.auth-popup .confirmation-code .confirmation-code__label').hide();
            $('.auth-popup .confirmation-code .confirmation-code__label-phone').show();

            $('.auth-popup [data-form="form-auth-popup__registration"] input[name="type"]').val('phone');
        } else if (type === 'whatsapp') {
            $('.auth-popup [data-form="form-auth-popup__registration"] .form-group').hide();
            $('.auth-popup [data-form="form-auth-popup__registration"] input').prop('disabled', true);
            $('.auth-popup [data-form="form-auth-popup__registration"] .form-group__phone').show();
            $('.auth-popup [data-form="form-auth-popup__registration"] input[name="phone"]').prop('disabled', false);
            $('.auth-popup .confirmation-code .confirmation-code__label').hide();
            $('.auth-popup .confirmation-code .confirmation-code__label-phone').show();

            $('.auth-popup [data-form="form-auth-popup__registration"] input[name="type"]').val('whatsapp');
        }

        $('.auth-popup__role').hide();
        $('.auth-popup [data-form-info]').html('').hide();
        $('.auth-popup [data-form-error]').html('').hide();
        $('.auth-popup button').prop('disabled', false);
        $('.auth-popup .confirmation-code__fields input').val('');
        $('.auth-popup__role[data-role="auth-popup-registration"]').show();
        $('.auth-popup .invalid-feedback').remove();
        $('.auth-popup input[type="text"]').val('').removeClass('valid invalid');
    });

    let startCheckEmailRequestTimeout = null;
    let startCheckCleantalkRequestTimeout = null;
    $('.auth-popup [data-form="form-auth-popup__registration"] input[name="email"]').on('input', function() {
        clearTimeout(startCheckEmailRequestTimeout);
        clearTimeout(startCheckCleantalkRequestTimeout);

        // Email check greylist
        let $form = $(this).closest('form');
        let $buttonSubmit = $form.find('[data-button-action="submit"]');

        formValidationErrorClear($form);

        let email = $(this).val();
        if (!email || !validateEmail(email)) {
            return;
        }

        $buttonSubmit.prop('disabled', true);

        startCheckEmailRequestTimeout = setTimeout(function() {
            let email = $('.auth-popup [data-form="form-auth-popup__registration"] input[name="email"]').val();

            axios.get(route('api.v1.check_greylist_email_hosting') + '?email=' + email).
                then(response => {
                    let data = (response?.data ? response.data : {});
                    if (!data) {
                        hideFormError($('.auth-popup [data-form="form-auth-popup__registration"]'));
                        return;
                    }

                    if (data.message) {
                        showFormError($('.auth-popup [data-form="form-auth-popup__registration"]'), data.message, 'warning');
                        return;
                    }

                    hideFormError($('.auth-popup [data-form="form-auth-popup__registration"]'));
                }).
                catch(error => {
                    hideFormError($('.auth-popup [data-form="form-auth-popup__registration"]'));
                });
        }, 350);

        // Email check Cleantalk
        if (jsAppData.capchaEnabled) {
            startCheckCleantalkRequestTimeout = setTimeout(function() {
                axios.get(route('api.v1.check_cleantalk') + '?email=' + email).
                    then(response => {
                        let data = (response?.data ? response.data : {});

                        if (data.data.needCaptcha) {
                            showCaptcha($buttonSubmit);
                            return;
                        }

                        $buttonSubmit.prop('disabled', false);
                    }).
                    catch(error => {
                        showCaptcha($buttonSubmit);
                    });
            }, 500);
        } else {
            $buttonSubmit.prop('disabled', false);
        }
    });

    // Phone input
    let $formRegistation = $('[data-form="form-auth-popup__registration"]');
    let phoneDialCode = $formRegistation.find('input[name="phone_dial_code"]').val() ?? '';
    let phoneCountryCode = $formRegistation.find('input[name="phone_country_code"]').val() ?? '';

    const itiInput = document.querySelector('.auth-popup input[name="phone"]');
    const iti = intlTelInput(itiInput, {
        strictMode: true,
        initialCountry: 'auto',
        nationalMode: false,
        dropdownContainer: document.querySelector('.auth-popup .form-group__phone'),
        // containerClass: '.auth-popup .form-group__phone',
        countrySearch: false,
        // autoPlaceholder: 'aggressive',
        // placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
        fixDropdownWidth: true,
        // formatAsYouType: true,
        // formatOnDisplay: true,
        showFlags: true,
        separateDialCode: false,
        useFullscreenPopup: false,
        loadUtils: () => import('intl-tel-input/utils'),
        geoIpLookup: callback => {
            fetch("https://ipapi.co/json")
            .then(res => res.json())
            .then(data => callback(data.country_code))
            .catch(() => callback("us"));
        },
    });

    itiInput.addEventListener('countrychange', () => {
        phoneDialCode = iti.getSelectedCountryData().dialCode ?? '';
        phoneCountryCode = iti.getSelectedCountryData().iso2 ?? '';

        $('.auth-popup input[name="phone_dial_code"]').val(phoneDialCode);
        $('.auth-popup input[name="phone_country_code"]').val(phoneCountryCode);

        if (phoneDialCode && $('.auth-popup input[name="phone"]').val() === '') {
            $('.auth-popup input[name="phone"]').val('+' + phoneDialCode);
        }
    });

    $('.auth-popup input[name="phone"]').on('input', function() {
        formValidationErrorClear($(this).closest('form'));
        let value = $(this).val();

        if (value.startsWith('00')) {
            value = value.replace('00', '+');
        }

        if (!value.startsWith('+')) {
            value = '+' + value;
        }

        $(this).val(value.replace(/[^0-9 \-+()]/g, ''));
    });

    $('.auth-popup input[name="phone"]').on('blur', function() {
        let value = $(this).val();
        if (!value.startsWith('+')) {
            iti.setNumber('+' + value);
        } else {
            iti.setNumber(value);
        }
    });

    if ($('.auth-popup input[name="phone"]').val() !== '') {
        $('.auth-popup input[name="phone"]').trigger('input');
    }
};

export const initAuthPopupScripts = function() {
    runIfHasSelecter('.auth-popup', [
        initScript,
    ]);
};
