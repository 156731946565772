import {checkOrderStatus, runIfHasSelecter} from '../../../utils/panel-lib.js';
import axios from 'axios';
import route from '../../index.js';
import {showCheckoutSuccessBlock} from '../index.js';

const initCommon = function()    {
  let $paymentQrCode = $('.qr-code-payment');
  let $paymentQrCodeBlock = $('.checkout-block-qr-code');
  let $paymentQrCodeError = $paymentQrCode.find('.qr-code-payment__error');
  let $buttonPay = $paymentQrCode.find('[data-ajax-action="qr-code"]');
  let $cart = $('.payment-order');
  let getOrderTimeout = null;
  let $loadingBlock = $paymentQrCode.find('.loading-block');
  let orderId = jsPageData.orderId ?? null;
  let repeatOrderPayment = jsPageData.repeatOrderPayment ? 1 : 0;

  const generateQrCode = function(str = '') {
    if (!str) {
      return;
    }
    let generatedQrCode = document.querySelector('.checkout-block-qr-code');
    if (generatedQrCode) {
      var qrcode = new QRCode(generatedQrCode, {
        text: str,
        width: 260,
        height: 260,
        colorDark : "#000000",
        colorLight : "#ffffff",
        correctLevel : QRCode.CorrectLevel.L
      });
    }
  }

  const createPayment = (data = {}) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let $currencySel = $('select[name="cg_currency"]');
    data.currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';
    data.paymentSystems = ['cg'];

    if (jsPageData.isCartFromOrder && orderId) {
      data.orderId = orderId;
      data.repeat = repeatOrderPayment;
    }

    $buttonPay.prop('disabled', true);
    $paymentQrCodeError.html('').hide();
    $paymentQrCodeBlock.show();
    $loadingBlock.show();

    axios.post(route('api.payment.commerce_gate.create_payment'), data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            $buttonPay.prop('disabled', false);
            return;
          }

          let status = data.status ?? '';
          if (status === 'failed' || data.errMessage) {
            if (data.errMessage) {
              $paymentQrCodeError.html(data.errMessage).show();
            } else if (status.errMessage) {
              $paymentQrCodeError.html(status.errMessage).show();
            }

            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          } else if (status === 'pending') {
            let orderId = parseInt(data.MetaData?.orderId ?? null);
            let referenceOrderId = data.orderId ?? null;

            if (orderId) {
              checkOrderStatus(orderId, 'pix');
            }
            getOrder(referenceOrderId);
          } else if (status === 'success') {
            showCheckoutSuccessBlock();
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
          $paymentQrCodeBlock.hide();
          $loadingBlock.hide();
        });
  };

  const getOrder = (referenceOrderId) => {
    clearTimeout(getOrderTimeout);

    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let data = {
      referenceOrderId: referenceOrderId,
    };

    $buttonPay.prop('disabled', true);

    axios.post(route('api.payment.commerce_gate.get_order'), data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            return;
          }

          let status = (data.status ? data.status : {});
          let orderId = parseInt(data.MetaData?.orderId ?? null);
          let referenceOrderId = data.orderId ?? null;

          if (status === 'failed') {
            if (data.errMessage) {
              $paymentQrCodeBlock.hide();
              $paymentQrCodeError.html(data.errMessage).show();
              $loadingBlock.hide();
            }
            $buttonPay.prop('disabled', false);
          } else if (status === 'success') {
            showCheckoutSuccessBlock();
          } else if (status === 'threeDSecureRequested') {
            if (data.ThreeDInfo && data.ThreeDInfo.barCodeString) {
              generateQrCode(data.ThreeDInfo.barCodeString);
              $loadingBlock.hide();
            } else {
              $paymentQrCodeBlock.hide();
              $paymentQrCodeError.html('Unable to create QR code').show();
              $loadingBlock.hide();
              $buttonPay.prop('disabled', false);
            }
          } else {
            getOrderTimeout = setTimeout(function() {
              getOrder(referenceOrderId);
            }, 1000);
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          getOrderTimeout = setTimeout(function() {
            getOrder(referenceOrderId);
          }, 5000);
        });
  };

  $buttonPay.on('click', function() {
    createPayment({paymentMethod: 'pix'});
  });
};

export const initCartPaymentMethodCommerceGatePixScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
