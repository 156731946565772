import {runIfHasSelecter} from '../../../utils/panel-lib.js';

const initCommon = function() {
  let $cryptoBlockchain = $('[name="crypto_blockchain"]');
  let cryptoCoins = jsPageData.cryptoCoins ?? [];
  let orderId = jsPageData.orderId ?? null;
  let repeatOrderPayment = jsPageData.repeatOrderPayment ? 1 : 0;

  let params = '';
  if (jsPageData.isCartFromOrder && orderId) {
    params = '&orderId=' + orderId + '&repeat=' + repeatOrderPayment;
  }

  $('[name="crypto_asset_id"]').on('change', function() {
    let selectedCoin = $(this).val();

    $cryptoBlockchain.find('option').each(function(index, el) {
      let blockchain = $(el).text();

      if (jQuery.inArray(selectedCoin, cryptoCoins[blockchain]) !== -1) {
        $(el).prop('selected', true).show();
      } else {
        $(el).prop('selected', false).hide();
      }
    });
    $cryptoBlockchain.selectpicker('destroy').selectpicker();

    $('[data-pay-by="crypto"]').attr('href', '/cart/do-pay?system=crypto&asset_id=' + selectedCoin + '&blockchain=' + $('[name="crypto_blockchain"]').val() + params);
    $('[data-pay-by="crypto"]').removeClass('disabled');
  }).trigger('change');

  $('[name="crypto_blockchain"]').change(function() {
    let selectedBlockchain = $(this).val();

    $('[data-pay-by="crypto"]').attr('href', '/cart/do-pay?system=crypto&asset_id=' + $('[name="crypto_asset_id"]').val() + '&blockchain=' + selectedBlockchain + params);
  });
};

export const initCartPaymentMethodCryptoScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
