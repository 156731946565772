// noinspection JSValidateTypes

import _, {toString} from 'lodash';
import {
  initHotspots,
  initTooltips,
  plusMinusToCart,
  sendGaEvent, updateCartWidget,
  updateCountItemInCart,
  updatePaymentOrder,
} from '../utils/panel-lib';
import Cookies from 'js-cookie';
import route from './index.js';

const initAction = () => {

  const $action = $("[data-role=action]");
  if ($action.length === 0) {
    return;
  }

  const timeleft = $action.data('action-timeleft');

  var seconds_passed = 0;
  function periodicall() {
    seconds_passed++;

    var distance = timeleft - Math.round(seconds_passed / 100);
    var days = Math.floor(distance / 86400);
    var hours = Math.floor(distance / 3600) - days * 24;
    var minutes = Math.floor((distance % 3600) / 60);
    var seconds = Math.floor(distance % 60);
    var seconds_parts = Math.floor(60 - 60 * (seconds_passed % 100) / 100);

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    if (seconds_parts < 10) seconds_parts = "0" + seconds_parts;

    let $promoBuyEl = $('.action__timeleft');
    if ($promoBuyEl.length > 0) {
      if (days > 0) {
        $promoBuyEl.html(`<span class="promo_days">${days}<span class="promo_days_title">D</span>&nbsp;</span><span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">s</span>&nbsp;</span><span class="promo_mseconds">:${seconds_parts}</span>`);
      } else {
        $promoBuyEl.html(`<span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">S</span>&nbsp;</span><span class="promo_mseconds">:${seconds_parts}</span>`);
      }
    }

    setTimeout(periodicall, 10);
  }

  periodicall();
}

const initPersonalDiscount = () => {
  const $action = $('[data-role="personal-discount"]');
  if ($action.length === 0) {
    return;
  }

  const $actionTimeleftEl = $action.find('.action__timeleft');
  if ($actionTimeleftEl.length <= 0) {
    $($action.find('.expires-in-text')).hide();
    return;
  }

  const timeleft = $action.data('action-timeleft');

  var seconds_passed = 0;
  function periodicall() {
    seconds_passed++;

    var distance = timeleft - Math.round(seconds_passed / 100);
    var days = Math.floor(distance / 86400);
    // var hours = Math.floor(distance / 3600) - days * 24;
    var hours = Math.floor(distance / 3600);
    var minutes = Math.floor((distance % 3600) / 60);
    var seconds = Math.floor(distance % 60);
    var seconds_parts = Math.floor(60 - 60 * (seconds_passed % 100) / 100);

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    if (seconds_parts < 10) seconds_parts = "0" + seconds_parts;

    let demoEl = document.getElementById("personal-discount-timer");
    if (demoEl) {
      demoEl.innerHTML = hours + "H " + minutes + "M " + seconds + "S " + " :" + seconds_parts;
    }

    // if (days > 0) {
    //   $promoBuyEl.html(`<span class="promo_days">${days}<span class="promo_days_title">D</span>&nbsp;</span><span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">s</span>&nbsp;</span><span class="promo_mseconds">:${seconds_parts}</span>`);
    // } else {
    //   $promoBuyEl.html(`<span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">S</span>&nbsp;</span><span class="promo_mseconds">:${seconds_parts}</span>`);
    // }

    $actionTimeleftEl.html(`<span class="promo_hours">${hours}<span class="promo_hours_title">H</span>&nbsp;</span><span class="promo_minutes">${minutes}<span class="promo_minutes_title">M</span>&nbsp;</span><span class="promo_seconds">${seconds}<span class="promo_seconds_title">s</span>&nbsp;</span><span class="promo_mseconds">:${seconds_parts}</span>`);

    setTimeout(periodicall, 10);
  }

  periodicall();
}

const initPersonalCoupons = () => {
  let $actions = $('[data-role="coupon-action"]');
  let secondsPassed = {};

  $actions.each(function (index, el) {
    let timeleft = $(el).data('action-timeleft');
    let $counterEl = $(el).find('.coupon-action__timeleft');

    secondsPassed[index] = 0;

    periodicall(index, timeleft, $counterEl);
  });

  function periodicall(index, timeleft, $counterEl) {
    secondsPassed[index]++;

    var distance = timeleft - Math.round(secondsPassed[index] / 100);
    var days = Math.floor(distance / 86400);
    var hours = Math.floor(distance / 3600) - days * 24;
    var minutes = Math.floor((distance % 3600) / 60);
    var seconds = Math.floor(distance % 60);
    var secondsParts = Math.floor(60 - 60 * (secondsPassed[index] % 100) / 100);

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    if (secondsParts < 10) secondsParts = "0" + secondsParts;

    if (days > 0) {
      $counterEl.html(days + "d " + hours + "h " + minutes + "m " + seconds + "s " + " :" + secondsParts);
    } else {
      $counterEl.html(hours + "h " + minutes + "m " + seconds + "s " + " :" + secondsParts);
    }

    setTimeout(function () {
      periodicall(index, timeleft, $counterEl);
    }, 10);
  }
}

const initDatapickerRange = function () {
  const $elPickerRange = $('[data-picker-range] input');
  if ($elPickerRange.length === 0) {
    return;
  }

  $elPickerRange.each(function (count, el) {
    if (typeof $(el).datepicker === "function") {
      $(el).datepicker({
        format: "dd.mm.yyyy",
        keyboardNavigation: false,
        forceParse: false,
        autoclose: true,
        startDate: '-1Y'
      });
    }
  });
}

const initSidebar = () => {
  $('.page-sidebar').hover(
      function() {
        $('body').addClass('sidebar-hover');
      },
      function() {
        $('body').removeClass('sidebar-hover');
      },
  );
}

const initMenuPlugin = () => {
  $.fn.extend({
    /**
     * pass the options variable to the function
     *
     *   $(id).navigation({
     *       accordion: true,
     *       animate: 'easeOutExpo',
     *       speed: 200,
     *       closedSign: '[+]',
     *       openedSign: '[-]',
     *       initClass: 'js-nav-built'
     *   });
     *
     **/

    navigation: function(options) {
      let defaults = {
            accordion: true,
            animate: 'easeOutExpo',
            speed: 200,
            closedSign: '[+]',
            openedSign: '[-]',
            initClass: 'js-nav-built',
          },

          /**
           * extend our default options with those provided.
           **/
          opts = $.extend(defaults, options),

          /**
           * assign current element to variable, in this case is UL element
           **/
          self = $(this);

      if (!self.hasClass(opts.initClass)) {
        /**
         * confirm build to prevent rebuild error
         **/
        self.addClass(opts.initClass);

        /**
         * add a mark [+] to a multilevel menu
         **/
        self.find('li').each(function() {
          if ($(this).find('ul').length !== 0) {
            /**
             * add the multilevel sign next to the link
             **/
            $(this).find('a:first').append('<b class=\'collapse-sign\'>' + opts.closedSign + '</b>');

            /**
             * avoid jumping to the top of the page when the href is an #
             **/
            if ($(this).find('a:first').attr('href') === '#') {
              $(this).find('a:first').click(function() {
                return false;
              });
            }
          }
        });

        /**
         * add open sign to all active lists
         **/
        self.find('li.active').each(function() {
          $(this).parents('li').addClass('active');
          $(this).parents('ul').parent('li').find('a:first').attr('aria-expanded', true).find('b:first').html(opts.openedSign);
        });

        /**
         * click events
         **/
        self.find('li a').on('mousedown', function() {
          if ($(this).parent().find('ul').length !== 0) {
            if (opts.accordion) {
              /**
               * do nothing when the list is open
               **/
              if (!$(this).parent().find('ul').is(':visible')) {
                let parents = $(this).parent().parents('ul');

                let visible = self.find('ul:visible');
                visible.each(function(visibleIndex) {
                  let close = true;
                  parents.each(function(parentIndex) {
                    if (parents[parentIndex] === visible[visibleIndex]) {
                      close = false;
                      return false;
                    }
                  });

                  if (close) {
                    if ($(this).parent().find('ul') !==
                        visible[visibleIndex]) {
                      $(visible[visibleIndex]).
                          slideUp(opts.speed,
                              opts.animate, function() {
                                $(this).
                                    parent('li').
                                    removeClass('open').
                                    find('a:first').
                                    attr('aria-expanded', false).
                                    find('b:first').
                                    html(opts.closedSign);
                              });
                    }
                  }
                });
              }
            }

            /**
             * Add active class to open element
             **/
            if ($(this).parent().find('ul:first').is(':visible') && !$(this).parent().find('ul:first').hasClass('active')) {
              $(this).
                  parent().
                  find('ul:first').
                  slideUp(opts.speed, opts.animate, function() {
                    $(this).
                        parent('li').
                        removeClass('open').
                        find('a:first').
                        attr('aria-expanded', false).
                        find('b:first').
                        delay(opts.speed).html(opts.closedSign);
                  });
            } else {
              $(this).
                  parent().
                  find('ul:first').
                  slideDown(opts.speed, opts.animate, function() {
                    $(this).
                        parent('li').
                        addClass('open').
                        find('a:first').
                        attr('aria-expanded', true).
                        find('b:first').
                        delay(opts.speed).
                        html(opts.openedSign);
                  });
            }
          }
        });
      }
    },
  });

  if ($.fn.navigation) {
    $('#js-nav-menu').navigation({
      accordion: true,
      speed: 100,
      closedSign: '<em class="fal fa-angle-down"></em>',
      openedSign: '<em class="fal fa-angle-up"></em>',
      initClass: 'js-nav-built',
    });
  }
}

const initMenu = () => {
  const currentPath = document.location.pathname;

  const activateMenuItem = function ($item) {
    $item.addClass('active').
        closest('ul').show().
        closest('li').addClass('open');

    $item.parents('li').addClass('active');
  }

  $('nav [data-item-active]').each(function() {
    if ($(this).data('item-active')) {
      activateMenuItem($(this));
    }
  });

  $('[data-paths]').each(function() {
    const $this = $(this);
    const config = $this.data('paths');

    _.map(config.exact || [], path => {
      if (currentPath === path) {
        activateMenuItem($this);
      }
    });

    _.map(config.starts || [], path => {
      if (_.startsWith(currentPath, path)) {
        activateMenuItem($this);
      }
    });
  });

  $('nav.primary-nav a[href^="/cart#"]').on('click', function () {
    window.location.href = $(this).attr('href');
    window.location.reload();
  });
}

const initGclidHandler = function () {
  const params = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
      function(_, key, value) {
        return params[key] = value;
      });

  const gclid = params['gclid'];
  const msclkid = params['msclkid'];
  if (gclid) {
    try {
      window.localStorage.setItem('gclid', gclid);
    } catch (e) {
      console.log('Error localStorage.setItem: ' + e.responseText);
    }
  }
  if (msclkid) {
    try {
      window.localStorage.setItem('msclkid', msclkid);
    } catch (e) {
      console.log('Error localStorage.setItem: ' + e.responseText);
    }
  }

  const $gclidInput = $('#gclid_field');
  if ($gclidInput.length > 0) {
    let newValue;
    try {
      newValue = window.localStorage.getItem('gclid') || '';
    } catch (e) {
      console.log('Error localStorage.getItem: ' + e.responseText);
    }

    $gclidInput.val(newValue);
    $gclidInput.attr("value", newValue);
  }
}

const initScreenResolutionHandler = function () {
  // let screenResolution = window.screen.width * window.devicePixelRatio + 'x' + window.screen.height * window.devicePixelRatio;
  let screenResolution = (window.screen.width && window.screen.height ? window.screen.width + 'x' + window.screen.height : 'undefined');

  if (document.location.href.indexOf('//localhost') !== -1) {
    Cookies.set('st_screen_resolution', screenResolution, {domain: '.localhost', sameSite: 'lax'});
  } else if (document.location.href.indexOf('//app.sparktraffic.test') !== -1) {
    Cookies.set('st_screen_resolution', screenResolution, {domain: '.app.sparktraffic.test', sameSite: 'lax'});
  } else {
    Cookies.set('st_screen_resolution', screenResolution, {domain: '.sparktraffic.com', sameSite: 'lax'});
  }

  $('[name="screen_resolution"]').val(screenResolution ? screenResolution : '');
}

const initSendGaEvent = function () {
  let userId = '';
  if (typeof userData !== 'undefined') {
    userId = userData.id ? userData.id : '';
  }

  if (window.__env__ !== 'production' || userId === '') {
    return;
  }

  $.ajax({
    url: '/api/transactions/get-for-send-to-ga',
    method: 'GET',
    dataType: 'json',
    success: function (response) {
      if (typeof response !== 'undefined' && response.success) {
        let data = response.data;

        if (data && data.transactions) {
          let transactions = data.transactions;
          if (!transactions) {
            return;
          }

          let transactionIds = [];

          for (let i = 0; i < transactions.length; i++) {
            let transaction = transactions[i];

            sendGaEvent('purchase', {
              // "debug_mode": true,
              "transaction_id": String(transaction.id),
              "currency": String((transaction.currency ? transaction.currency : 'USD')),
              "value": parseFloat(transaction.amount),
              "items": [{
                "id": String(transaction.plan_option_id),
                "name": String(transaction.description),
                "price": parseFloat(transaction.amount)
              }]
            });

            transactionIds.push(transaction.id);
          }

          if (transactionIds) {
            $.ajax({
              url: '/api/transactions/update-was-sent-to-ga',
              method: 'POST',
              data: {
                transactionIds: transactionIds
              },
              dataType: 'json'
            });
          }
        }
      }
    }
  });
}

export const initBootstrapSelect = function () {
  const $elCustomSelect = $('.custom-select');
  if ($elCustomSelect.length === 0) {
    return;
  }

  // $.fn.selectpicker.Constructor.BootstrapVersion = '5';

  $elCustomSelect.each(function (count, el) {
    if (typeof $(el).selectpicker === "function") {
      let dataContainer = $(el).data('container');

      $(el).selectpicker({
        // liveSearch: true,
        // liveSearchStyle: 'contains',
        container: dataContainer ? dataContainer : 'body',
        dropupAuto: false,
        // multipleSeparator: ','
        // virtualScroll: 600,
        showTick: true,
      });

      let bsContainerId = $(el).closest('.bootstrap-select').find('[aria-owns]').attr('aria-owns');
      let isMultiselect = $(el).hasClass('multiselect');
      if (!isMultiselect) {
        $('#' + bsContainerId).on('click', function () {
          $(el).selectpicker('toggle');
        });
      }
    }
  });
}

const initDropdownBlock = function () {
  const $dropdownButtons = $('.dropdown-button-block');

  if ($dropdownButtons.length === 0) {
    return;
  }

  $dropdownButtons.each(function () {
    const $button = $(this);
    const $block = $button.next();

    if ($block.length > 0 && $block.hasClass('dropdown-block')) {
      const $buttonClose = $block.find('[data-action="close"]');
      const positionDropdown = $button.data('position-dropdown');

      if (positionDropdown && positionDropdown === 'left') {
        $block.css({
          'right': 'auto',
          'left': '0'
        });
      }

      $button.on('click', function (e) {
        e.preventDefault();

        $button.toggleClass('open');
      });

      $buttonClose.on('click', function () {
        $button.removeClass('open');
      });
    }
  });

  $(document).on('click', function (e) {
    const $targetButtonClick = $(e.target).closest('.dropdown-button-block');
    const $targetClick = $(e.target).closest('.dropdown-block');

    if ($targetButtonClick.length <= 0 && $targetClick.length <= 0 && !$(e.target).hasClass('dropdown-button-block')) {
      $dropdownButtons.removeClass('open');
    }
  });
}

const initButtonsDropdown = function () {
  const $dropdownButtons = $('.dropdown-button');

  if ($dropdownButtons.length === 0) {
    return;
  }

  $dropdownButtons.each(function () {
    const $button = $(this);
    const $menu = $button.next();

    if ($menu.length > 0 && $menu.hasClass('dropdown-menu')) {
      const $buttonClose = $menu.find('.button-close');
      const positionDropdown = $button.data('position-dropdown');

      if (positionDropdown && positionDropdown === 'left') {
        $menu.css({
          'right': 'auto',
          'left': '0'
        });
      }

      $button.on('click', function (e) {
        e.preventDefault();

        $button.toggleClass('open');
      });

      $buttonClose.on('click', function () {
        $button.removeClass('open');
      });
    }
  });

  $(document).on('click', function (e) {
    const $targetButtonClick = $(e.target).closest('.dropdown-button');
    const $targetClick = $(e.target).closest('.dropdown-menu');

    if ($targetButtonClick.length <= 0 && $targetClick.length <= 0 && !$(e.target).hasClass('dropdown-button')) {
      $dropdownButtons.removeClass('open');
    }
  });
}

const initGoToHashTag = function () {
  $('a[href^="#"]').on('click', function(){
    const target = $(this).attr('href');

    if (target === '#' || $(this).data('type') === 'accordion' || $(this).data('toggle') === 'tab' || $(this).data('bs-toggle') === 'tab') {
      return true;
    }

    if ($(target).length > 0) {
      $('html, body').animate({scrollTop: $(target).offset().top}, 300);

      return false;
    }

    return true;
  });

  $('a[href*="#tab"]').on('click', function(){
    if ($(this).data('toggle') === 'tab' || $(this).data('bs-toggle') === 'tab') {
      return true;
    }

    let hashtag = new URL($(this).attr('href')).hash;
    let target = $('.nav-item [href="' + hashtag + '"]');

    if (target.length > 0 && typeof target.tab() !== 'undefined') {
      window.localStorage.setItem('lastTab', hashtag);
      target.tab('show');

      return false;
    }
  });
}

const initTabGoToHashTag = function () {
  let hashtag = window.location.hash.replace(/^#_/,'');

  if (hashtag === '#tab-company'
      || hashtag === '#tab_web_traffic_credits'
      || hashtag === '#tab_seo_traffic_credits'
      || hashtag === '#tab_backlinks_credits')
  {
    window.localStorage.setItem('lastTab', hashtag);
    let tabEl = $('.nav-item [href="' + hashtag + '"]');
    if (tabEl.length > 0 && typeof tabEl.tab() !== 'undefined') {
      tabEl.tab('show');
    }
  } else if (hashtag !== '') {
    // window.localStorage.setItem('st-cart-nav-tab', hashtag);
    let tabEl =  $('.buy-tabs .nav-item [href="' + hashtag + '"]');
    if (tabEl.length > 0 && typeof tabEl.tab() !== 'undefined') {
      tabEl.tab('show');
    }
  }
}

const initButtonsPanelCollapse = function () {
  $('.see-recommendations a, .warning-block a').on('click', function () {
    const target = $(this).attr('href');

    $(target).removeClass('panel-collapsed');
  });

  $('[data-button-action="panel-collapse"]').on('click', function () {
    let $panel = $(this).closest('.st-panel');
    let panelId = $panel.data('panel-id');

    $panel.toggleClass('panel-collapsed');

    if (panelId) {
      try {
        window.localStorage.setItem('st-pc-' + panelId, $panel.hasClass('panel-collapsed'));
      } catch (e) {
        console.log('Error localStorage.setItem: ' + e.responseText);
      }
    }
  });

  $('[data-panel-id]').each(function () {
    let $panel = $(this);
    let panelId = $panel.data('panel-id');

    let localStoragePanel;
    try {
      localStoragePanel = JSON.parse(window.localStorage.getItem('st-pc-' + panelId));
    } catch (e) {
      console.log('Error localStorage.getItem: ' + e.responseText);
    }

    if (localStoragePanel !== null && localStoragePanel !== 'null' && localStoragePanel) {
      if (localStoragePanel) {
        $panel.addClass('panel-collapsed');
      } else {
        $panel.removeClass('panel-collapsed');
      }
    }
  });
}

const initButtonsDisallow = function () {
  $('[data-disallow]').on('click', function () {
    let $disallow = $(this).data('disallow');
    let $disallowGroup = $(this).data('disallow-group');

    if ($disallow && $disallow === 'double-click') {
      if ($disallowGroup) {
        $('[data-disallow-group="' + $disallowGroup + '"]').each(function (count, el) {
          if ($(el).is('button')) {
            $(this).prop('disabled', true);
          } else {
            $(this).addClass('disabled');
          }
        });
      } else {
        if ($(this).is('button')) {
          $(this).prop('disabled', true);
        } else {
          $(this).addClass('disabled');
        }
      }

      if ($(this).attr('type') === 'submit') {
        $(this).closest('form').trigger('submit');
      }
    }
  });
}

const initShowMoreButtons = function () {
  $('[data-button="show-more"]').on('click', function () {
    let $showMoreContainer = $(this).next('.show-more-container');
    if ($showMoreContainer.length > 0) {
      $(this).hide();
      $showMoreContainer.stop().fadeToggle();
    }
  });
}

const initCart = function () {
  let $cartElements = $('.mini-cart, .payment-order, .cart-widget');
  let $errorBox = $('.error-box');
  let $loadingBlock = $('.loading-block');

  if ($cartElements.length <= 0) {
    return;
  }

  $cartElements.on('click', function (e) {
    // Delete from Cart
    if ($(e.target).hasClass('button-delete') || $(e.target).closest('.button-delete').length > 0) {
      let $button = $(e.target);
      if ($(e.target).closest('.button-delete').length > 0) {
        $button = $(e.target).closest('.button-delete');
      }

      let currentUrl = window.location.href;
      let selectPlanOption = $button.closest('.cart-list__item');
      let planOptionId = parseInt(selectPlanOption.data('id'));
      let $loadingBlock = $('.loading-block');

      let data = {
        id: planOptionId
      };

      $('.payment-order').trigger('deleting-from-cart');
      $loadingBlock.addClass('processing');
      $errorBox.html('').hide();

      $.ajax({
        url: '/api/cart/delete-from-cart',
        method: 'DELETE',
        data: data,
        dataType: 'json',
        success: function (response) {
          if (response.success) {
            updatePaymentOrder();
          } else {
            if (response.message) {
              $errorBox.html(response.message).show();
            }
            $loadingBlock.removeClass('processing');
          }
        },
        error: function () {
          $errorBox.html('An error occurred while adding to cart').show();
          $loadingBlock.removeClass('processing');
        }
      });
    }

    // Show/Hide cart items
    if ($(e.target).hasClass('cart-list__item-header') || $(e.target).closest('.cart-list__item-header').length > 0) {
      let $button = $(e.target);
      if ($(e.target).closest('.cart-list__item-header').length > 0) {
        $button = $(e.target).closest('.cart-list__item-header');
      }

      let cartListItemContainer = $button.closest('.cart-list__item-wrap').find('.cart-list__item-container');

      if (cartListItemContainer.length > 0) {
        $button.toggleClass('open');
        cartListItemContainer.toggle();
      }
    }

    if ($('body').data('device-type') !== 'desktop') {
      // Show mobile cart
      if ($(e.target).hasClass('mini-cart__footer') || $(e.target).closest('.mini-cart__footer').length > 0) {
        $('.mini-cart__fixed-hide').toggle();
        $('.mini-cart__button-fixed-hide').toggle();
      }

      // Hide mobile cart
      if ($(e.target).hasClass('mini-cart__button-fixed-hide') || $(e.target).closest('.mini-cart__button-fixed-hide').length > 0) {
        $('.mini-cart__fixed-hide').hide();
        $('.mini-cart__button-fixed-hide').hide();
      }
    }
  });

  $cartElements.on('adding-to-cart', function() {
    $loadingBlock.addClass('processing');

    $('[data-ajax-action="add-to-cart"]').prop('disabled', true);
    $('[data-ajax-action="delete"]').prop('disabled', true);
    $('.cart-list__plus-minus .input-number').prop('disabled', true);
    $('.cart-list__plus-minus .btn-number').prop('disabled', true);
  });

  $cartElements.on('deleting-from-cart', function() {
    $loadingBlock.addClass('processing');

    $('[data-ajax-action="add-to-cart"]').prop('disabled', true);
    $('[data-ajax-action="delete"]').prop('disabled', true);
    $('.cart-list__plus-minus .input-number').prop('disabled', true);
    $('.cart-list__plus-minus .btn-number').prop('disabled', true);
  });

  $cartElements.on('cart-updating', function() {
    $loadingBlock.addClass('processing');

    $('[data-ajax-action="add-to-cart"]').prop('disabled', true);
    $('[data-ajax-action="delete"]').prop('disabled', true);
    $('.cart-list__plus-minus .input-number').prop('disabled', true);
    $('.cart-list__plus-minus .btn-number').prop('disabled', true);
  });

  $cartElements.on('cart-updated-success', function() {
    $loadingBlock.removeClass('processing');

    // Coupon & VAT
    $('[data-ajax-action="coupon-change"]').on('click', function(e) {
      $('.coupon-block__applied').hide();
      $('.coupon-block').show();
    });

    $('.button-show-vat').off('click').on('click', function () {
      $('.button-show-vat-js').hide();
      $('.vat-block').show();

      if (!$('.button-show-coupon-js').is(':visible')) {
        $('.other-data').hide();
      }
    });

    $('.button-show-coupon').off('click').on('click', function () {
      $('.button-show-coupon-js').hide();
      $('.coupon-block').show();

      if (!$('.button-show-vat-js').is(':visible')) {
        $('.other-data').hide();
      }
    });

    updateCountItemInCart();
    plusMinusToCart();
  });

  $cartElements.on('cart-updated-error', function() {
    $loadingBlock.removeClass('processing');
  });

  $cartElements.on('cart-updated-complete', function() {
    $('[data-ajax-action="add-to-cart"]').prop('disabled', false);
    $('[data-ajax-action="delete"]').prop('disabled', false);
    $('.cart-list__plus-minus .input-number').prop('disabled', false);
    $('.cart-list__plus-minus .btn-number').prop('disabled', false);

    $('.cart-list__plus-minus .input-number').each(function(index, el) {
      if (parseInt($(el).val()) <= 1) {
        $(el).closest('.cart-list__plus-minus').find('.button-number-minus').prop('disabled', true);
      }
    });

    $('select[name="currency"]').trigger('change');
  });
}

const initCartWidget = function () {
  let $cartWidgetWrap = $('.cart-widget-wrap');

  if ($cartWidgetWrap.length <= 0) {
    return;
  }

  updateCartWidget();
}

const initAddToCartSnake = function() {
  let addToCartButtons = document.querySelectorAll('[data-ajax-action="add-to-cart"]');
  let cartStep = document.querySelector('.cart-step');

  addToCartButtons.forEach(addToCartButton => {
    let buyCreditsBlock = addToCartButton.closest('.plan-option');
    if (!buyCreditsBlock) {
      return;
    }

    let cartTossWrapper = buyCreditsBlock.querySelector('.cart-toss-number');
    if (!cartTossWrapper) {
      return;
    }

    let cartTossNumber = buyCreditsBlock.querySelector('.cart-toss-number .number');
    if (!cartTossNumber) {
      return;
    }

    addToCartButton.addEventListener('click', function() {
      cartTossWrapper.classList.add('show');

      let distanceFromTop = cartStep.getBoundingClientRect().top - cartTossNumber.getBoundingClientRect().top;
      let distanceFromLeft = cartTossNumber.getBoundingClientRect().left;
      let distanceFromCartStep = cartStep.getBoundingClientRect().top;
      let viewportWidth = window.innerWidth;
      let transformDistanceY = distanceFromTop;
      let transformDistanceX = distanceFromLeft - (280 + 60);

      cartTossWrapper.style.transform = 'translateX(-' + transformDistanceX + 'px)';
      cartTossNumber.style.transform = 'translateY(' + transformDistanceY + 'px)';

      setTimeout(() => {
        cartTossWrapper.classList.remove('show');
        cartStep.classList.add('shake');
        setTimeout(() => {
          cartStep.classList.remove('shake');
        }, 400);
      }, 750);

      setTimeout(() => {
        cartTossWrapper.style.transform = 'translateX(0px)';
        cartTossNumber.style.transform = 'translateY(0px)';
      }, 1000);
    });
  });
};

const initMobileDataTableSearch = function () {
  $('.search-box .icon-search').on('click', function () {
    $('.search-box #search_table').toggleClass('open');
  });
}

const initLastTab = function() {
  $('a[data-bs-toggle="tab"]').on('shown.bs.tab', function() {
    try {
      window.localStorage.setItem('lastTab', $(this).attr('href'));
    } catch (e) {
      console.log('Error localStorage.setItem: ' + e.responseText);
    }
  });

  let lastTab;
  try {
    lastTab = window.localStorage.getItem('lastTab');
  } catch (e) {
    console.log('Error localStorage.getItem: ' + e.responseText);
  }

  if (lastTab) {
    let tabEl = $('[href="' + lastTab + '"]');
    if (tabEl.length > 0 && typeof tabEl.tab() !== 'undefined') {
      tabEl.tab('show');
    }
  }
};

const initCopyTextFromElement = function() {
  async function writeClipboardText(text) {
    try {
      await navigator.clipboard.writeText(text);
      console.log(text);
    } catch (error) {
      console.error(error.message);
    }
  }

  $('[data-button-action="copy"]').on('click', function() {
    let targetForCopy = $(this).data('target-action');
    writeClipboardText($(targetForCopy).text()).then(r => {});
  });
};

const eventOnlanguageSelection = function() {
  $('#language-selector').on('change', function() {
    let selectedLanguage = $(this).val();
    if (selectedLanguage && jsAppData.userUid) {
      axios.patch(route('api.v1.user.interface_settings.update'), {selectedLanguage: selectedLanguage}).
          then(r => {}).
          catch(e => {});
    }
  });
};

const detectLanguageForDemoUser = function() {
  if (jsAppData.language && $('#language-selector option[value="' + jsAppData.language + '"]').length > 0) {
    try {
      window.localStorage.setItem('wt-selected-language', jsAppData.language);
    } catch (e) {
      // nothing
    }
  }
};

export const initCommonScripts = function () {
  initAction();
  initPersonalDiscount();
  initPersonalCoupons();
  initGoToHashTag();
  initTabGoToHashTag();
  initDatapickerRange();
  initSidebar();
  initMenuPlugin();
  initMenu();
  initGclidHandler();
  initScreenResolutionHandler();
  initSendGaEvent();
  initBootstrapSelect();
  initTooltips();
  initHotspots();
  initDropdownBlock();
  initButtonsDropdown();
  initButtonsPanelCollapse();
  initButtonsDisallow();
  initShowMoreButtons();
  initCart();
  // initAddToCartSnake();
  initCartWidget();
  initMobileDataTableSearch();
  initLastTab();
  initCopyTextFromElement();
  eventOnlanguageSelection();
  detectLanguageForDemoUser();
}
