import {
  checkOrderStatus, formValidationErrorHandling,
  getExchangeRate,
  runIfHasSelecter,
} from '../../../utils/panel-lib.js';
import axios from 'axios';
import route from '../../index.js';
import {displayIframe3dVerification, hideCheckoutErrorBlock, showCheckoutErrorBlock, showCheckoutSuccessBlock} from '../index.js';
import {showFormError} from '@app-frontend/panel/auth-popup/index.js';

const initCommon = function()    {
  let $paymentFormWrap = $('.commerce-gate-checkout .form-payment-card-wrap');
  let $paymentForm = $('.commerce-gate-checkout form[data-form-id="form-payment-card"]');
  let $paymentFormsErrors = $('.form-payment-card .form-payment-card__error');
  let $buttonPay = $paymentForm.find('[data-ajax-action="pay-now"]');
  let $cart = $('.payment-order');
  let isCheckoutTopForm = $('.commerce-gate-checkout.top-form').length > 0;
  let getOrderTimeout = null;
  let $iframe3dVerification = $('.iframe-3d-verification');
  let $loadingBlock = $paymentFormWrap.find('.loading-block');
  let loadedIframe3ds = false;
  let completedCheck3ds = false;
  let orderId = jsPageData?.orderId ?? null;
  let repeatOrderPayment = jsPageData?.repeatOrderPayment ? 1 : 0;
  let paymentId = null;
  let isBacklinksCart = jsPageData?.isBacklinksCart ?? false;

  const createPayment = (data = {}) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let endpoint = isBacklinksCart ? route('api.payment.commerce_gate.create_payment_backlinks') : route('api.payment.commerce_gate.create_payment');
    let $currencySel = $('select[name="cg_currency"]');

    data.currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';
    data.paymentSystems = ['cg'];

    if (jsPageData.isCartFromOrder && orderId) {
      data.orderId = orderId;
      data.repeat = repeatOrderPayment;
    }

    $buttonPay.prop('disabled', true);
    $loadingBlock.show();

    axios.post(endpoint, data).
        then(response => {
          let responseData = (response.data ? response.data : {});
          if (!responseData) {
            data.orderId = orderId
            // data.repeat = 1;
            data.paymentSystems = null;

            createPaymentUnlimit(data);

            return;
          }

          let status = responseData.status ?? '';
          if (status === 'failed' || responseData.errMessage) {
            if (responseData.errMessage) {
              $paymentFormsErrors.html(responseData.errMessage).show();
            } else if (status.errMessage) {
              $paymentFormsErrors.html(status.errMessage).show();
            }

            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();

            data.orderId = orderId
            // data.repeat = 1;
            data.paymentSystems = null;

            createPaymentUnlimit(data);
          } else if (status === 'pending') {
            let orderId = parseInt(responseData.MetaData?.orderId ?? null);
            let referenceOrderId = responseData.orderId ?? null;

            if (orderId) {
              checkOrderStatus(orderId, 'cg');

              $('.payment-order').on('order-declined', function() {
                data.orderId = orderId
                // data.repeat = 1;
                data.paymentSystems = null;

                createPaymentUnlimit(data);
              });
            }

            getOrder(referenceOrderId);
          } else if (status === 'success') {
            showCheckoutSuccessBlock('cg');
            $loadingBlock.hide();
          }
        }).
        catch(error => {
          console.log('There was an error:', error);

          let data = (error?.response?.data ? error.response.data : {});

          if (data.errors) {
            formValidationErrorHandling($paymentForm, data.errors);
          }

          if (data.message) {
            $paymentFormsErrors.html(data.message).show();
          }

          $buttonPay.prop('disabled', false);
          $loadingBlock.hide();
        });
  };

  const recurringPayment = (data = {}) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let endpoint = isBacklinksCart ? route('api.payment.commerce_gate.recurring_payment_backlinks') : route('api.payment.commerce_gate.recurring_payment');

    let $currencySel = $('select[name="cg_currency"]');
    data.currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';
    data.paymentSystems = ['cg'];

    if (jsPageData.isCartFromOrder && orderId) {
      data.orderId = orderId;
      data.repeat = repeatOrderPayment;
    }

    $('.checkout-block-info [data-button-action="repeat"]').hide();
    $buttonPay.prop('disabled', true);
    $loadingBlock.show();

    axios.post(endpoint, data).
        then(response => {
          let responseData = (response.data ? response.data : {});
          if (!responseData) {
            data.orderId = orderId;
            $buttonPay.prop('disabled', false);

            return;
          }

          let status = responseData.status ?? '';
          if (status === 'failed' || responseData.errMessage) {
            if (responseData.errMessage) {
              $paymentFormsErrors.html(responseData.errMessage).show();
            } else if (status.errMessage) {
              $paymentFormsErrors.html(status.errMessage).show();
            }

            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();

            data.orderId = orderId;
          } else if (status === 'pending') {
            let orderId = parseInt(responseData.MetaData?.orderId ?? null);
            let referenceOrderId = responseData.orderId ?? null;

            if (orderId) {
              checkOrderStatus(orderId, 'cg');

              $('.payment-order').on('order-declined', function() {
                data.orderId = orderId
              });
            }

            getOrder(referenceOrderId);
          } else if (status === 'success') {
            showCheckoutSuccessBlock('cg');
            $loadingBlock.hide();
          } else if (responseData.message) {
            $paymentFormsErrors.html(responseData.message).show();
            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
          $loadingBlock.hide();
        });
  };

  const getOrder = (referenceOrderId) => {
    clearTimeout(getOrderTimeout);

    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let data = {
      referenceOrderId: referenceOrderId,
    };

    $buttonPay.prop('disabled', true);

    axios.post(route('api.payment.commerce_gate.get_order'), data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            return;
          }

          let status = (data.status ? data.status : {});
          let orderId = parseInt(data.MetaData?.orderId ?? null);
          let referenceOrderId = data.orderId ?? null;

          if (status === 'failed') {
            if (data.errMessage) {
              if (data.errCode && data.errCode === '480') {
                $paymentFormsErrors.html('Your bank declined the payment: <b>Insufficient funds</b>').show();
              } else {
                $paymentFormsErrors.html(data.errMessage).show();
              }
            } else {
              $paymentFormsErrors.html('Payment system error').show();
            }

            $loadingBlock.hide();
            $buttonPay.prop('disabled', false);
          } else if (status === 'success') {
            showCheckoutSuccessBlock('cg');
          } else if (status === 'threeDSecureRequested') {
            displayIframe3dVerification(true);

            if (data.ThreeDInfo && data.ThreeDInfo.acsServerUrl) {
              $loadingBlock.show();
              $iframe3dVerification.attr('src', data.ThreeDInfo.acsServerUrl);
              $iframe3dVerification.on('load', function(){
                $loadingBlock.hide();
                loadedIframe3ds = true;
              });

              setTimeout(function() {
                if (!loadedIframe3ds) {
                  displayIframe3dVerification(false);
                  $iframe3dVerification.remove();
                  $loadingBlock.hide();
                  $paymentFormsErrors.html('Payment failed').show();
                }
              }, 60000);
            }
          } else {
            getOrderTimeout = setTimeout(function() {
              getOrder(referenceOrderId);
            }, 1000);
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          getOrderTimeout = setTimeout(function() {
            getOrder(referenceOrderId);
          }, 5000);
        });
  };

  const createPaymentUnlimit = (data = {}) => {
    $buttonPay.prop('disabled', false);
    $loadingBlock.hide();

    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let endpoint = (data.isSubscription === 0 ? route('api.payment.unlimit.create-payment') : route('api.payment.unlimit.create-subscription'));
    if (isBacklinksCart) {
      endpoint = route('api.payment.unlimit.create-payment-backlinks');
    }

    $('.payment-order').off('order-declined');
    hideCheckoutErrorBlock('cg');
    $('.checkout-block-payment[data-payment-method="cg"]').attr('data-payment-method', 'unlimit');
    $('.checkout-block-info[data-payment-method="cg"]').attr('data-payment-method', 'unlimit');
    $('.checkout-block-info [data-button-action="repeat"]').show();
    $paymentFormsErrors.html('').hide();

    $buttonPay.prop('disabled', true);
    $loadingBlock.show();

    // todo temporary
    // axios.post(route('api.payment.unlimit.create-first-recurring-payment'), data).
    axios.post(endpoint, data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            $buttonPay.prop('disabled', false);

            return;
          }

          let redirectUrl = data.redirect_url ?? '';
          let status = data.payment_data?.status ?? data.recurring_data?.status ?? '';
          let orderId = data.orderId ?? null;
          let errorMessage = data.message ?? data.payment_data?.decline_reason ?? data.recurring_data?.decline_reason ?? '';
          paymentId =  data.payment_data?.id ?? null;

          if (orderId) {
            checkOrderStatus(orderId, 'unlimit');
          }

          if (status === 'DECLINED' || data.errMessage || errorMessage) {
            if (data.errMessage) {
              if (errorMessage === 'Insufficient funds') {
                $paymentFormsErrors.html('Your bank declined the payment: <b>Insufficient funds</b>').show();
              } else {
                $paymentFormsErrors.html(data.errMessage).show();
              }
            } else if (errorMessage) {
              $paymentFormsErrors.html(errorMessage).show();
            }

            showCheckoutErrorBlock('unlimit');
            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          } else if (status === 'COMPLETED') {
            showCheckoutSuccessBlock('unlimit');
            $loadingBlock.hide();
          } else if (redirectUrl) {
            displayIframe3dVerification(true);

            $loadingBlock.show();
            $iframe3dVerification.attr('src', redirectUrl);
            $iframe3dVerification.on('load', function(){
              $loadingBlock.hide();
              loadedIframe3ds = true;
            });

            setTimeout(function() {
              if (!loadedIframe3ds) {
                displayIframe3dVerification(false);
                $iframe3dVerification.remove();
                $loadingBlock.hide();
                $paymentFormsErrors.html('Payment failed').show();
              }
            }, 60000);
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
          $loadingBlock.hide();
        });
  };

  $paymentForm.off('submit').on('submit', function(e) {
    e.preventDefault();

    $paymentFormsErrors.html('').hide();

    let cardId = $paymentForm.find('[name="card_id"]').val();
    let cardNumber = $paymentForm.find('[name="card_number"]').val();
    let cardName = $paymentForm.find('[name="card_name"]').val();
    let cardExpirationM = $paymentForm.find('[name="card_expiration_m"]').val();
    let cardExpirationY = $paymentForm.find('[name="card_expiration_y"]').val();
    let cardCvv = $paymentForm.find('[name="card_cvv"]').val();

    let isSubscription = ($('#order_as_subscription').length > 0 ? ($('#order_as_subscription').prop('checked') ? 1 : 0) : null);
    if (isBacklinksCart) {
      isSubscription = false;
    }

    let backlinkProjectId = $('[name="backlink_project_id"]').val() ?? null;
    let quantity = parseInt($('[name="package_quantity"]').val() ?? 0);

    if (cardId && cardId !== '0') {
      recurringPayment({
        cardId: cardId,
        isSubscription: isSubscription,
        isBacklinksCart: isBacklinksCart,
        backlinkProjectId: backlinkProjectId,
        quantity: quantity,
      });

      return true;
    }

    if (!cardNumber || !cardName || !cardExpirationY || !cardExpirationY || !cardCvv) {
      return;
    }

    createPayment({
      cardId: cardId,
      cardNumber: cardNumber,
      cardName: cardName,
      cardExpirationM: cardExpirationM,
      cardExpirationY: cardExpirationY,
      cardCvv: cardCvv,
      isSubscription: isSubscription,
      isBacklinksCart: isBacklinksCart,
      backlinkProjectId: backlinkProjectId,
      quantity: quantity,
    });
  });

  $('[data-ajax-action="pay-now-cg"]').on('click', function() {
    $(this).closest('.payment-options__button').hide();
    $('.checkout-block').show();
  });

  $cart.on('cart-updated-complete', function(event, data) {
    let amountToPay = data.amountToPay ?? 0;
    let currency = data.currency ?? 'USD';
    if (currency !== 'USD' && currency !== 'EUR') {
      currency = 'USD';
    }
    let vatRate = parseFloat(data.vatRate ?? 0).toFixed(2);

    hideCheckoutErrorBlock('cg');
    hideCheckoutErrorBlock('unlimit');
    $paymentFormsErrors.html('').hide();

    if (isCheckoutTopForm) {
      $('.checkout-block').show();

      if ($('.payment-order__total-amount').hasClass('amount-zero')) {
        $('.checkout-block-payment[data-payment-method="cg"]').hide();
      } else {
        $('.checkout-block-payment[data-payment-method="cg"]').show();
      }
    }

    $('[data-js-amount]').html(amountToPay.toFixed(2));
    $('[data-js-currency]').html(currency);
    $('[data-js-vat]').html((vatRate * 100) + '%');

    if (currency !== 'USD') {
      getExchangeRate(currency).then(function(response) {
        if (response.data && response.data.success && response.data.data) {
          let data = response.data.data;
          let rate = data.rate ? data.rate : 1.0;
          let amountCurrency = (amountToPay * parseFloat(rate)).toFixed(2);

          $('[data-js-amount]').html(amountCurrency);
          $('[data-js-currency]').html(currency);
        }
      });
    }
  });
};

export const initCartPaymentMethodCommerceGatePaymentsAndUnlimitScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('.commerce-gate-checkout', [
    initCommon
  ]);
};
