import {formValidationErrorClear, formValidationErrorHandling, runIfHasSelecter} from '../utils/panel-lib';
import axios from 'axios';
import route from './index.js';

const initAction = () => {
    let $buttonSubmit = $('button[type="submit"]');
    $buttonSubmit.on('click', function (e) {
        e.preventDefault();

        let $form = $(this).closest('form');
        let $formInfo = $form.find('[data-form-info]');
        let $userEmail = $form.find('input[name="email"]');

        formValidationErrorClear($form);
        $formInfo.html('').hide();
        $(this).prop('disabled', true);

        if (!jsPageData.userEmail && $userEmail.val() && $userEmail.val() !== $userEmail.data('value')) {
            let data = {
                email: $userEmail.val(),
            };

            axios.post(route('api.v1.user.email_confirmation'), data).
                then(response => {
                    let data = (response?.data ? response.data : {});
                    if (!data) {
                        $buttonSubmit.prop('disabled', false);
                        return;
                    }

                    if (data.errors) {
                        formValidationErrorHandling($form, data.errors);
                        $buttonSubmit.prop('disabled', false);
                        return;
                    }

                    if (data.message) {
                        $formInfo.html(data.message).show();
                        $buttonSubmit.prop('disabled', false);
                        return;
                    }

                    $formInfo.html('Please follow the link in the email sent to your email address <b>' + $userEmail.val() + '</b>').show();
                }).
                catch(error => {
                    let data = (error?.response?.data ? error.response.data : {});
                    if (data.errors) {
                        formValidationErrorHandling($form, data.errors);
                    }

                    $buttonSubmit.prop('disabled', false);
                });
        } else {
            $form.trigger('submit');
        }
    });
}

const initEmailNotifications = () => {
    let notificationsStatusesSwitches = $('[data-ajax-action="set-notifications-status"]');
    let unsubscribeFromAllSwitch = $('[data-ajax-action="unsubscribe-from-all"]');

    notificationsStatusesSwitches.on('change', function () {
        let $switch = $(this);
        let groupId = $switch.data('group-id');
        let status = $switch.prop('checked') ? 1 : 0;

        $switch.prop('disabled', true);

        $.ajax({
            url: '/api/user/email-notification/set-status',
            method: 'POST',
            data: {
                group_id: groupId,
                status: status,
            },
            dataType: 'json',
            success: function (response) {
                if (!response.success) {
                    if (status === 1) {
                        $switch.prop('checked', false);
                    } else {
                        $switch.prop('checked', true);
                    }
                }
            },
            error: function () {
                if (status === 1) {
                    $switch.prop('checked', false);
                } else {
                    $switch.prop('checked', true);
                }
            },
            complete: function () {
                $switch.prop('disabled', false);
            }
        });
    });

    unsubscribeFromAllSwitch.on('change', function () {
        let $switch = $(this);
        let status = $switch.prop('checked') ? 1 : 0;

        $switch.prop('disabled', true);

        $.ajax({
            url: '/api/user/email-notifications/unsubscribe-from-all',
            method: 'POST',
            data: {
                status: status,
            },
            dataType: 'json',
            success: function (response) {
                if (!response.success) {
                    if (status === 1) {
                        notificationsStatusesSwitches.prop('disabled', false);
                        $switch.prop('checked', false);
                    } else {
                        notificationsStatusesSwitches.prop('disabled', true);
                        $switch.prop('checked', true);
                    }
                } else {
                    if (status === 1) {
                        notificationsStatusesSwitches.prop('disabled', true);
                    } else {
                        notificationsStatusesSwitches.prop('disabled', false);
                    }
                }
            },
            error: function () {
                if (status === 1) {
                    notificationsStatusesSwitches.prop('disabled', false);
                    $switch.prop('checked', false);
                } else {
                    notificationsStatusesSwitches.prop('disabled', true);
                    $switch.prop('checked', true);
                }
            },
            complete: function () {
                $switch.prop('disabled', false);
            }
        });
    });
}

export const initProfileScripts = function () {
    runIfHasSelecter('[data-role="profile"]', [
        initAction,
        initEmailNotifications
    ]);
}
